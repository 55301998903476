<div class="schoolRegisterView">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">{{headings}}</h2>
            </div>
        </div>
    </div>
    <ng-container *ngIf="topErrorMessage">
        <div class="row err" *ngFor="let obj of keys1 ">
            <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                <p class="col-md-12 error text-center" id="err">{{value}}</p>
            </div>
        </div>
    </ng-container>
    <div class="row" *ngIf="!topErrorMessage">
        <div class="col-md-12 error text-center">
            {{errorMsg}}
        </div>
    </div>
    <div class="container" id="nsicc-modal-content">
        <div class="form-ajax-box" *ngIf="!formLoader; else showLoader">
            <form class="form-horizontal nsicc-form validated" [formGroup]="schoolPaymentForm" (ngSubmit)="onSubmit()">
                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                    <div class="row">
                        <label> {{ this.type ==0 ?"Registration Payment" : "Student Fees Payment" }}</label>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                            <label class="form-label question_label" for="payment_amount"> Total Fee
                                (in CAD) <span class="asterix"> *
                                </span></label>
                            <input type="text" name="payment_amount" formControlName="payment_amount"
                                [(ngModel)]="paidAmount" id="payment_amount" value="" required=""
                                [readonly]="paidAmount" class="form-control" (keypress)="numericOnly($event)">
                        </div>
                        <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-12">
                            <label for="Mode of payment" class="col-12 p-0 mt-2 form-label"> Mode of payment<span
                                    class="asterix">
                                    *
                                </span></label>
                            <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                <label class="form-label" class="text-muted form-check-label mr-5" for="Cash"
                                    *ngIf="showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="1"
                                        class="filled-in form-check-input" id="payment_method-0"
                                        (change)="changeName('Cash')"
                                        [ngClass]="{ 'is-invalid': submitted && schoolPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"> <label class="form-label"
                                        for="payment_method-0">Cash
                                    </label>
                                </label>
                                <label class="form-label" class="form-check-label mr-5" for="payment_method-3"
                                    *ngIf="!showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="3"
                                        class="filled-in form-check-input" id="payment_method-3"
                                        (change)="changeName('E-Transfer')"
                                        [ngClass]="{ 'is-invalid': submitted && schoolPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-3">E-Transfer</label>
                                </label>
                                <label class="form-label" class="form-check-label  mr-5" for="Online"
                                    *ngIf="!showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="4"
                                        class="filled-in form-check-input" id="payment_method-2"
                                        (change)="changeName('Online')"
                                        [ngClass]="{ 'is-invalid': submitted && schoolPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-2">Online</label>
                                </label>

                                <label class="form-label" class="form-check-label" for="payment_method-4"
                                    *ngIf="showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="5"
                                        class="filled-in form-check-input" id="payment_method-4"
                                        (change)="changeName('POS')"
                                        [ngClass]="{ 'is-invalid': submitted && schoolPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-4">POS</label>
                                </label>
                            </div>
                            <div *ngIf="submitted && schoolPaymentFormControl.payment_method.errors"
                                class="invalid-feedback">
                                <div *ngIf="schoolPaymentFormControl.payment_method.errors.required">
                                    Mode of payment
                                    is
                                    required.
                                </div>
                            </div>

                            <div *ngIf="schoolPaymentForm.value.payment_method == 3" class="info">
                                <p class="">To send donations through EMAIL transfer from your bank account, please
                                    use the following information:</p>
                                <address>
                                    Name: Kearney Lake Masjid<br>
                                    Email: <a href="mailto:treasurer@nsicc.ca">treasurer&#64;nsicc.ca</a><br>
                                    Security Question: What is the donation for?<br>
                                    Security Answer: general<br>
                                </address>
                                <p>Enter the amount and purpose you want to donate and send</p>
                            </div>
                            <div class="mt-2 mb-2"
                                *ngIf="schoolPaymentForm.value.payment_method == 1&& type==1 && showtoAdmin">
                                <mat-form-field>
                                    <mat-label>Set Custom Payment Amount </mat-label>
                                    <input matInput type="number" min="0" max="{{ paidAmount }}"
                                        formControlName="cash_amount" autocomplete="off" step="1"
                                        placeholder="Change The Default Payment Amount" (keyup)="minMaxCheck(amount)"
                                        (keypress)="numericOnly($event)">
                                </mat-form-field>
                            </div>
                            <div class="row" *ngIf="schoolPaymentForm.value.payment_method == 4">
                                <lable class="form-label">Payment Frequency <span class="asterix">
                                        *
                                    </span></lable>
                                <mat-radio-group formControlName="payment_frequency" aria-label="Select an option">


                                    <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                        <label class="form-check-label ml-2 mr-5" for="payment_frequency-1">
                                            <input type="radio" formControlName="payment_frequency"
                                                name="payment_frequency" value="1" class="filled-in form-check-input"
                                                id="payment_frequency-1"
                                                [ngClass]="{ 'is-invalid': submitted && schoolPaymentForm?.payment_frequency?.errors }"
                                                data-parsley-multiple="payment_frequency_method"><label
                                                class="form-label" for="payment_frequency-1">One Time </label>
                                        </label> <label class="form-check-label  mr-5" for="payment_frequency-4"
                                            *ngIf="type==1">
                                            <input type="radio" formControlName="payment_frequency"
                                                name="payment_frequency" value="4" class="filled-in form-check-input"
                                                id="payment_frequency-4"
                                                [ngClass]="{ 'is-invalid': submitted && schoolPaymentForm?.payment_frequency?.errors }"
                                                data-parsley-multiple="payment_frequency_method"><label
                                                class="form-label" for="payment_frequency-4">Monthly </label>
                                        </label>
                                    </div>



                                    <div *ngIf="submitted && schoolPaymentForm?.payment_frequency?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="schoolPaymentForm?.payment_frequency?.errors.required">
                                            Payment Frequency
                                            is
                                            required.
                                        </div>
                                    </div>
                                </mat-radio-group>

                            </div>
                            <div class="row number-of-months" *ngIf="schoolPaymentForm.value.payment_frequency == 4">
                                <label class="form-label"> Number of Months: {{this.dialogData.data.data.months
                                    }}</label>

                            </div>
                            <div class="loading-overlay" *ngIf="isLoading">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">Loading...</div>
                            </div>
                            <ng-container
                                *ngIf="schoolPaymentForm.value.payment_method == 4 &&  schoolPaymentForm.value.payment_frequency != 1  && schoolPaymentForm.value.payment_frequency !== ''">

                                <app-custom-checkout (sendToken)="onSubmit($event)" [amount]="amount"
                                    [duration]="duration" [payWord]="payWord" [message]="formMessage">
                                </app-custom-checkout>


                            </ng-container>
                        </div>
                    </div>
                    <div class="row mb-4" style="align-items:baseline;">
                        <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex my-3">
                            <div class="btn-group mr-2"
                                *ngIf="schoolPaymentForm.value.payment_frequency == 1 ||schoolPaymentForm.value.payment_frequency === '' ">
                                <button class="btn btn-primary" [disabled]="loadFlag2" name="save"
                                    (click)="onSubmit()">{{buttonText}} <i *ngIf="loadFlag2"
                                        class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ng-template #showLoader>
            <div class="text-center loader">
                <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                </mat-progress-spinner>
            </div>
        </ng-template>
    </div>
</div>
