<!-- <div class="title mb-4 col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12" *ngIf="!matchUrl('/checkout')">
    <mat-card-title>{{heading()}}</mat-card-title>
    <button class="btn btn-primary" (click)="createNew()" *ngIf="create && !matchUrl('add-event') && !matchUrl('edit-event') && !matchUrl('view-event') && !matchUrl('upcomingevent') && !matchUrl('/myevents') && !matchUrl('/register-event') && !matchUrl('/checkout') && !matchUrl('/view-myevent')">Register New Event</button>
</div>
<div class="main-content">
<router-outlet></router-outlet>
</div> -->
<div class="row title justify-content">
    <div class="col-12 col-md-6 col-lg-2 col-xl-2 col-sm-6 text-lg-center text-xl-center mx-auto" *ngIf="!matchUrl('/checkout') ">
        <mat-card-title class="text-lg-center text-xl-center">{{heading()}}</mat-card-title>
    </div>

</div>
<div class="main-content">
<router-outlet></router-outlet>
</div>