import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from '../shared/checkout/checkout.component';
import { AddDonationComponent } from './components/donations/add-donation/add-donation.component';
import { AllDonationsComponent } from './components/donations/all-donations/all-donations.component';
import { ViewDonationComponent } from './components/donations/view-donation/view-donation.component';
import { PurposeOfDonationListComponent } from './components/purpose_of_donation/purpose-of-donation-list/purpose-of-donation-list.component';
import { DonationsComponent } from './donations/donations.component';
import { UnsubscribeComponent } from '../finance/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: '',
    component: DonationsComponent,
    children: [
      { path: 'alldonation', component: AllDonationsComponent },
      { path: 'add-donation', component: AddDonationComponent },
      { path: 'edit-donation', component: AddDonationComponent },
      { path: 'view-donation', component: ViewDonationComponent },
      { path: 'purposeofdonation', component: PurposeOfDonationListComponent },
      { path: 'checkout', component: CheckoutComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DonationsRoutingModule {}
