<div class="">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">     <p>create-custom-recurrence works!</p> </h2>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="nsicc-modal-content">
        <div class="form-ajax-box">
            <form (ngSubmit)="onSubmit(form)" #form="ngForm">
                <div class="form-group">
                <label for="name">Character Name</label>
                <input type="text" id="name" name="name" [(ngModel)]="data.name" class="form-control">
                </div>
                <div class="form-group">
                <label for="side">Choose Side</label>
                <input type="text" id="city" name="city" [(ngModel)]="data.city" class="form-control">

                </div>
                </form>
            <!-- <div mat-dialog-content>
                <p>Name:
                  <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="data.name" placeholder="Enter name">
                  </mat-form-field>
                </p>
                <p>City:
                  <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="data.city" placeholder="Enter city">
                  </mat-form-field>
                </p>
              </div>
              <div mat-dialog-actions>
                <button mat-button (click)="onCancelUserDialog()">Cancel</button>
                <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
              </div>  -->  
              <div class="row">
                <div class="col">
                    <button mat-button (click)="cancel()">Cancel</button>
                    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
                </div>
              </div>
              
            
        </div>
    </div>
</div>