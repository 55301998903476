import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-funeral-settings',
  templateUrl: './funeral-settings.component.html',
  styleUrls: ['./funeral-settings.component.css'],
})
export class FuneralSettingsComponent extends BaseComponent implements OnInit {
  funeralSettingsForm: any;

  formMessage: string;
  amount: number;
  funeralSettingsUrl: string = AppConstants.BASE_URL + '/funeral-settings';

  constructor(
    private formBuilder: FormBuilder,
    dataManagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.service = dataManagerService;
    this.headings = 'Funeral Settings';
    this.router = router;
    this.buttonText = 'Submit';
  }

  ngOnInit(): void {
    this.setFuneralSettingsForm();
    this.getFormData();
  }
  get funeralSettingsFormControl() {
    return this.funeralSettingsForm.controls;
  }
  cancel() {
    this.router.navigate(['../funeral'], { relativeTo: this.route });
  }
  getFormData() {
    this.formLoader = true;

    this.service.getById(this.funeralSettingsUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          if (res.data) {
            this.formLoader = false;
            this.data = res?.data;
            this.amount = this.data.funeral_amount;

            this.buttonText = 'Submit';
          } else {
            this.formLoader = false;
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.funeralSettingsForm.invalid) {
      this.formMessage = 'Please Fill Required Fields';
      return;
    } else {
      this.loadFlag2 = true;

      this.service
        .create(this.funeralSettingsUrl, this.funeralSettingsForm.value)
        .subscribe({
          next: (res: any) => {
            if (
              res &&
              res['status'] &&
              res['status'] == 'success' &&
              res.data
            ) {
              this.loadFlag2 = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
              this.router.navigate(['../funeral'], {
                relativeTo: this.route,
              });
            } else {
              this.loadFlag2 = false;

              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = ['funeral_amount'];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
            }
          },
          error: (error: any) => {
            this.loadFlag2 = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
  setFuneralSettingsForm(): void {
    this.funeralSettingsForm = this.formBuilder.group({
      funeral_amount: [
        this.data && this.data.funeral_amount ? this.data.funeral_amount : '',
        [Validators.required, Validators.min(0.01), Validators.max(1000000)],
      ],
    });
  }
}
