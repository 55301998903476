import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy/MM/dd',
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'yyyy/MM/dd',
    dateA11yLabel: 'yyyy/MM/dd',
    monthYearA11yLabel: 'yyyy/MM/dd',
  },
};

@Component({
  selector: 'app-weeks-school',
  templateUrl: './weeks-school.component.html',
  styleUrls: ['./weeks-school.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class WeeksSchoolComponent extends BaseComponent implements OnInit {
  public schoolForm: FormGroup;

  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public academicList_url: string;
  public gradeList_url = AppConstants.BASE_URL + '/masterstudentgrade';
  public classList_url: string;
  public minDate: Date;
  public maxDate: Date;
  public academicList: any[] = [];
  public gradeList: any[] = [];
  public classList: any[] = [];
  public relationShip: ['Father', 'Mother'];
  public routeSub: any;
  public getChildDetails_url: string;
  discounts: any[] = [];
  public priceUrl: string = AppConstants.BASE_URL + '/get-students-discount';
  public schoolRegistration_url: string;
  public showRegFees = false;
  registerationAmount: any = 0;
  feesId: any;
  studentCostArray: any;
  public numOfStudent: number = 0;
  public calFees: number = 0;
  public totalFees: number = 0;
  activeYears: number = 1;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dataManagerService: DataManagerService,
    private datePipe: DatePipe,
  ) {
    super();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(
      currentYear - 20,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.maxDate = new Date(
      currentYear + 0,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.getChildDetails_url =
      AppConstants.BASE_URL + AppConstants.ADDWEEKENDSCHOOL_REG_URL;
    if (
      this.matchUrl('edit-weekend-school') ||
      this.matchUrl('add-weekend-school')
    ) {
      this.schoolRegistration_url =
        AppConstants.BASE_URL + AppConstants.ADDWEEKENDSCHOOL_REG_URL;
      this.classList_url =
        AppConstants.BASE_URL + '/masterstudentclass?school_type=1';
    } else {
      this.schoolRegistration_url =
        AppConstants.BASE_URL + AppConstants.ADDWEEKDAYSCHOOL_REG_URL;
      this.classList_url =
        AppConstants.BASE_URL + '/masterstudentclass?school_type=2';
    }
    this.route.url.subscribe((segments) => {
      const currentURL = segments.map((segment) => segment.path).join('/');
      if (
        currentURL.includes('add-weekend-school') ||
        this.matchUrl('edit-weekend-school')
      ) {
        // The current URL contains "add-weekend-school"
        this.academicList_url = AppConstants.BASE_URL + '/get-academic-year/1';
      } else {
        this.academicList_url = AppConstants.BASE_URL + '/get-academic-year/2';
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.formLoader = true;
        this.dataManagerService
          .getById(this.schoolRegistration_url + '/' + params['id'])
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                if (res.data) {
                  if (res.data.contact_number) {
                    const phone = res.data.contact_number.match(
                      /(\d{3})(\d{3})(\d{4})/,
                    );
                    res.data.contact_number =
                      '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
                  }
                  if (res.data.whatsapps_number) {
                    const phone = res.data.whatsapps_number.match(
                      /(\d{3})(\d{3})(\d{4})/,
                    );
                    res.data.whatsapps_number =
                      '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
                  }
                }
                this.data = res.data;
                this.setSchoolChildForm();
                this.setChildForm(this.data.no_of_child);
                this.headings = 'Update School Registration';
                this.buttonText = 'Update';

                this.getAcademicYearList();
                this.getGradeList();
                this.getClassList();
                this.formLoader = false;
              } else {
                this.formLoader = false;
                this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.formLoader = false;
              this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
            },
          });
      } else if (params['copy']) {
        this.formLoader = true;
        this.dataManagerService
          .getById(this.schoolRegistration_url + '/' + params['copy'])
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                if (res.data) {
                  if (res.data.contact_number) {
                    const phone = res.data.contact_number.match(
                      /(\d{3})(\d{3})(\d{4})/,
                    );
                    res.data.contact_number =
                      '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
                  }
                  if (res.data.whatsapps_number) {
                    const phone = res.data.whatsapps_number.match(
                      /(\d{3})(\d{3})(\d{4})/,
                    );
                    res.data.whatsapps_number =
                      '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
                  }
                  res.data.academic_year = null;
                  res.data.id = null;
                }
                this.data = res.data;
                this.setSchoolChildForm();
                this.setChildForm(this.data.no_of_child);
                this.headings = 'School Registration';
                this.buttonText = 'Submit';

                this.getAcademicYearList();
                this.getGradeList();
                this.getClassList();
                this.formLoader = false;
              } else {
                this.formLoader = false;
                this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.formLoader = false;
              this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
            },
          });
      } else {
        this.headings = 'Create School Registration Fees';
        this.headings = 'Create School Registration Fees';
        this.buttonText = 'Submit';
        this.getAcademicYearList();
        this.getGradeList();
        this.getClassList();
        this.formLoader = false;
      }
    });
  }
  ngOnInit(): void {
    this.setSchoolChildForm();
  }

  get schoolFormControl() {
    return this.schoolForm.controls;
  }
  get childFormControl() {
    return this.schoolForm.controls.childForm as FormArray;
  }
  setSchoolChildForm() {
    this.schoolForm = this.formBuilder.group({
      parent_full_name: [
        this.data && this.data.parent_full_name
          ? this.data.parent_full_name
          : '',
        [Validators.required],
      ],
      relationship_with_child: [
        this.data && this.data.relationship_with_child
          ? this.data.relationship_with_child
          : '',
        [Validators.required],
      ],
      contact_number: [
        this.data && this.data.contact_number ? this.data.contact_number : '',
        [Validators.required],
      ],
      no_of_child: [
        this.data && this.data.no_of_child ? this.data.no_of_child : '',
        [Validators.required],
      ],

      whatsapps_available: [
        this.data && this.data.whatsapps_available
          ? this.data.whatsapps_available
          : '1',
        [Validators.required],
      ],
      email: [
        this.data && this.data.email ? this.data.email : '',
        [Validators.required],
      ],
      whatsapps_number: [
        this.data && this.data.whatsapps_number
          ? this.data.whatsapps_number
          : '',
      ],
      academic_year: [
        this.data && this.data.academic_year ? this.data.academic_year : '',
        Validators.required,
      ],
      childForm: new FormArray([]),
    });
  }
  setChildForm(e: any) {
    const childs = !this.isNullOrUndefined(e.target) ? e.target.value : e || 0;
    if (this.childFormControl.length < childs) {
      for (let i = this.childFormControl.length; i < childs; i++) {
        this.childFormControl.push(
          this.formBuilder.group({
            student_name: [
              this.data && this.data.child_details[i].student_name
                ? this.data.child_details[i].student_name
                : '',
              [Validators.required],
            ],
            student_grade: [
              this.data && this.data.child_details[i].student_grade
                ? Number(this.data.child_details[i].student_grade)
                : '',
              Validators.required,
            ],
            student_class: [
              this.data && this.data.child_details[i].student_class
                ? this.data.child_details[i].student_class
                : '',
              Validators.required,
            ],
            dob: [
              this.data && this.data.child_details[i].dob
                ? this.datePipe.transform(
                    this.data.child_details[i].dob,
                    'yyyy-MM-dd',
                  )
                : '',
              [Validators.required],
            ],
            gender: [
              this.data &&
              (this.data.child_details[i].gender == 0 ||
                this.data.child_details[i].gender == 1)
                ? this.data.child_details[i].gender.toString()
                : '',
              [Validators.required],
            ],
            CanReadArabic: [
              this.data &&
              (this.data.child_details[i].CanReadArabic == 0 ||
                this.data.child_details[i].CanReadArabic == 1)
                ? this.data.child_details[i].CanReadArabic.toString()
                : '',
              [Validators.required],
            ],
            CanWriteArabic: [
              this.data &&
              (this.data.child_details[i].CanWriteArabic == 0 ||
                this.data.child_details[i].CanWriteArabic == 1)
                ? this.data.child_details[i].CanWriteArabic.toString()
                : '',
              [Validators.required],
            ],
            IsRegisterKLM: [
              this.data &&
              (this.data.child_details[i].IsRegisterKLM == 0 ||
                this.data.child_details[i].IsRegisterKLM == 1)
                ? this.data.child_details[i].IsRegisterKLM.toString()
                : '',
              [Validators.required],
            ],
            child_memory: [
              this.data && this.data.child_details[i].child_memory
                ? this.data.child_details[i].child_memory
                : '',
            ],
            additional_info: [
              this.data && this.data.child_details[i].additional_info
                ? this.data.child_details[i].additional_info
                : '',
            ],
            pickup_person_name: [
              this.data && this.data.child_details[i].pickup_person_name
                ? this.data.child_details[i].pickup_person_name
                : '',
              [Validators.required],
            ],
            IsArabicSpeak: [
              this.data &&
              (this.data.child_details[i].IsArabicSpeak == 0 ||
                this.data.child_details[i].IsArabicSpeak == 1)
                ? this.data.child_details[i].IsArabicSpeak
                : '',
            ],
            IsEnglishSpeak: [
              this.data &&
              (this.data.child_details[i].IsEnglishSpeak == 0 ||
                this.data.child_details[i].IsEnglishSpeak == 1)
                ? this.data.child_details[i].IsEnglishSpeak
                : '',
            ],
            IsOtherSpeak: [
              this.data &&
              (this.data.child_details[i].IsOtherSpeak == 0 ||
                this.data.child_details[i].IsOtherSpeak == 1)
                ? this.data.child_details[i].IsOtherSpeak
                : '',
            ],
            id: [this.data && this.data.child_details[i].id],
          }),
        );
      }
    } else {
      for (let i = this.childFormControl.length; i >= childs; i--) {
        this.childFormControl.removeAt(i);
      }
    }
  }
  onChangeStudents(e: any) {
    this.showRegFees = true;
    this.getEachStudentCost();
    const childs = !this.isNullOrUndefined(e.target) ? e.target.value : e || 0;
    if (this.childFormControl.length < childs) {
      for (let i = this.childFormControl.length; i < childs; i++) {
        this.childFormControl.push(
          this.formBuilder.group({
            student_name: ['', [Validators.required]],
            pickup_person_name: ['', [Validators.required]],
            student_grade: ['', Validators.required],
            student_class: [''],
            dob: ['', [Validators.required]],
            gender: ['', [Validators.required]],
            CanReadArabic: ['', [Validators.required]],
            CanWriteArabic: ['', [Validators.required]],
            IsRegisterKLM: ['', [Validators.required]],
            child_memory: [''],
            additional_info: [''],
            IsArabicSpeak: [''],
            IsEnglishSpeak: [''],
            IsOtherSpeak: [''],
          }),
        );
      }
    } else {
      for (let i = this.childFormControl.length; i >= childs; i--) {
        this.childFormControl.removeAt(i);
      }
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.schoolForm.value.whatsapps_available == '1') {
      this.schoolForm.value.whatsapps_number =
        this.schoolForm.value.contact_number;
    }
    if (this.schoolForm.invalid) {
      return;
    } else {
      this.loadFlag2 = true;
      this.schoolForm.value.childForm.forEach((element: any) => {
        for (let i in element) {
          if (i == 'dob') {
            element[i] = this.datePipe.transform(element[i], 'yyyy-MM-dd');
          }
          if (i == 'IsArabicSpeak') {
            element[i] = element[i] == true ? 1 : 0;
          } else if (i == 'IsEnglishSpeak') {
            element[i] = element[i] == true ? 1 : 0;
          } else if (i == 'IsOtherSpeak') {
            element[i] = element[i] == true ? 1 : 0;
          }
        }
      });
      this.schoolForm.value.contact_number =
        this.schoolForm.value.contact_number.replace(/\D+/g, '');
      this.schoolForm.value.whatsapps_number =
        this.schoolForm.value.whatsapps_number.replace(/\D+/g, '');
      if (this.data) {
        this.schoolForm.value.id = this.data.id;
      }

      this.dataManagerService
        .create(this.schoolRegistration_url, this.schoolForm.value)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              this.showRegFees = false;

              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              if (
                this.matchUrl('/schools/add-weekend-school') ||
                this.matchUrl('/schools/edit-weekend-school')
              ) {
                this.router.navigate(['/schools/weekendschoolregistration']);
              } else {
                this.router.navigate(['/schools/elfurqanhifdh']);
              }
            } else if (res['status'] == 'error') {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = [
                'parent_full_name',
                'relationship_with_child',
                'contact_number',
                'no_of_child',
                'whatsapps_available',
                'email',
                'whatsapps_number',
                'academic_year',
              ];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
              this.dataManagerService.showSnackBar(
                'Please fill Required fields',
                'ok',
                3000,
              );
            } else if (res['status'] == 'error') {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = [
                'parent_full_name',
                'relationship_with_child',
                'contact_number',
                'no_of_child',
                'whatsapps_available',
                'email',
                'whatsapps_number',
                'academic_year',
              ];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
              this.dataManagerService.showSnackBar(
                'Please fill Required fields',
                'ok',
                3000,
              );
            } else {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys1 = Object.values(this.topErrorMessage);
              this.dataManagerService.showSnackBar(this.keys1[0], 'ok', 3000);
              this.topErrorMessage = res.message;
              this.keys1 = Object.values(this.topErrorMessage);
              this.dataManagerService.showSnackBar(this.keys1[0], 'ok', 3000);
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.topErrorMessage = error.errors;
            this.keys = Object.keys(this.topErrorMessage);
            this.keys1 = Object.values(this.topErrorMessage);
            let i;
            let j;
            const name = [
              'parent_full_name',
              'relationship_with_child',
              'contact_number',
              'no_of_child',
              'whatsapps_available',
              'email',
              'whatsapps_number',
              'academic_year',
            ];
            for (i = 0; i < this.keys.length; i++) {
              for (j = 0; j < name.length; j++) {
                if (this.keys[i].match(name[j])) {
                  const documents = document.getElementById(name[j]);
                  if (documents) {
                    documents.style.borderColor = '#a94442';
                    documents.style.display = 'block';
                  }
                }
              }
            }
            this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
  cancel() {
    if (
      this.matchUrl('/schools/add-weekend-school') ||
      this.matchUrl('/schools/edit-weekend-school') ||
      this.matchUrl('/schools/view-weekend-school')
    ) {
      this.router.navigate(['/schools/weekendschoolregistration']);
    } else {
      this.router.navigate(['/schools/weekdayschoolregistration']);
    }
  }
  getAcademicYearList() {
    this.dataManagerService.nonTableList(this.academicList_url).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.academicList = res.data;
          this.activeYears = this.academicList.length;
          console.log('this.activeYears', this.activeYears);
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }

  getGradeList() {
    this.dataManagerService.nonTableList(this.gradeList_url).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.gradeList = res.data;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  getAcademicYearDetails() {
    this.academicList.forEach((element) => {
      if (element.academic_year_id == this.schoolForm.value.academic_year) {
        this.registerationAmount = element.register_amount;
        this.feesId = element.feesId;
        this.getEachStudentCost();
      }
    });
  }
  getClassList() {
    this.dataManagerService.nonTableList(this.classList_url).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.classList = res.data.data;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  public totals: number = 0;
  calculateTotal(): number {
    if (this.studentCostArray != undefined) {
      this.totals = this.studentCostArray.reduce(
        (prev: number, next: number) => prev + next,
        0,
      );
      return this.totals;
    } else {
      return 0;
    }
  }
  getEachStudentCost() {
    if (this.feesId && this.schoolForm.value.no_of_child) {
      let formData = new FormData();
      formData.set('feesId', this.feesId);
      formData.set('noOfStudents', this.schoolForm.value.no_of_child);
      this.dataManagerService.create(this.priceUrl, formData).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.studentCostArray = res.data;
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    }
  }
  onWhatsAppOptionChange(value: string) {
    if (value === '0') {
      // If value equals '1' (Yes), update WhatsApp number with parent's phone number
      const parentPhoneNumber = this.schoolForm.value.contact_number;
      console.log('parentPhoneNumber', parentPhoneNumber);
      this.schoolForm.value.whatsapps_number = parentPhoneNumber;
      this.schoolForm.controls['whatsapps_number'].clearValidators();
      this.schoolForm.controls['whatsapps_number'].updateValueAndValidity();
    } else {
      this.schoolForm.controls['whatsapps_number'].setValidators([
        Validators.required,
      ]);
      this.schoolForm.value.whatsapps_number = '';
    }
  }
}
