import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddPurposeOfDonationDialogComponent } from '../../add-purpose-of-donation-dialog/add-purpose-of-donation-dialog.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-custom-recurrence',
  templateUrl: './create-custom-recurrence.component.html',
  styleUrls: ['./create-custom-recurrence.component.css'],
})
export class CreateCustomRecurrenceComponent implements OnInit {
  public recurrencesOptions: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<CreateCustomRecurrenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit data', this.data);
  }
  onSubmit(formdata: any) {
    console.log('formdata', formdata);
  }
  cancel() {
    this.dialogRef.close(true);
  }
}
