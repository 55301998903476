<div class="content">
    <div class="toolbar-nav">
        <div class="col-12 p-0 d-flex align-items-baseline justify-content-center">
            <h1 class="title text-center">Discounts</h1>
        </div>
    </div>
    <div class="row justify-content-space-between">
        <div class="col-lg-4 col-xl-4 col-md-8 col-sm-12 col-12 form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                placeholder="Search" [(ngModel)]="searchText" (input)="searchHits.next(true)" />
        </div>
        <!-- [(ngModel)]="searchText" (input)="searchHits.next(true)"(click)="createNew()" -->
        <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12 text-right">
            <button class="btn btn-primary mr-2" (click)="createNew()">
                Add New Discount</button>

        </div>
    </div>
    <div>
        <div appearance="outlined" class="list">
            <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true" [loader]="loader"
                [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                (getPagination)="getPageData($event)" (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                [sqColumnDefinition]="columnDefinition" [length]="resultsLength"></app-generic-table>
        </div>
    </div>

</div>

<ng-template #showLoader>
    <div class="text-center loader">
        <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
        </mat-progress-spinner>
    </div>
</ng-template>