import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.onInputChange(event.clipboardData?.getData('text'));
  }

  onInputChange(value: any) {
    if (this.isNullOrUndefined(value)) {
      value = value;
    } else {
      const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    this.ngControl.valueAccessor?.writeValue(value);
  }
  isNullOrUndefined(value: any) {
    return value == null || value == undefined;
  }
}
