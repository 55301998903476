import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-view-fundraiser',
  templateUrl: './view-fundraiser.component.html',
  styleUrls: ['./view-fundraiser.component.css'],
})
export class ViewFundraiserComponent extends BaseComponent implements OnInit {
  fundraiser: any;
  urlParam: any;
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  apiUrl: string;
  totalPages: number;
  funds: any;
  searchQuery: string;
  loading: boolean = false;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
  ) {
    super();
    this.service = dataManagerService;
    this.route.params.subscribe((params) => {
      if (params) {
        this.urlParam = params;
        this.apiUrl =
          AppConstants.BASE_URL + /fundraisers/ + params['fund_id'] + '/funds';
      }
    });
  }

  ngOnInit(): void {
    this.getFundraisers();
    this.fetchData();
  }
  fetchData(): void {
    this.loading = true;
    this.service
      .getPaginatedData(
        this.apiUrl,
        this.currentPage,
        this.pageSize,
        this.searchQuery,
      )
      .subscribe(
        (response) => {
          this.funds = response.data.data;
          this.totalItems = response.data.pagination.total;
          this.totalPages = response.data.pagination.lastPage;
        },
        (error) => {
          console.error('Error fetching data:', error);
        },
      )
      .add(() => {
        this.loading = false;
      });
  }
  onSearch(): void {
    this.currentPage = 1;
    this.fetchData();
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.fetchData();
  }

  onPageSizeChange(newSize: number): void {
    this.pageSize = newSize;
    this.currentPage = 1;
    this.fetchData();
  }

  getFundraisers() {
    if (this.urlParam) {
      this.httpClient
        .get(AppConstants.BASE_URL + '/fundraisers/' + this.urlParam['fund_id'])
        .subscribe(
          (data: any) => {
            this.fundraiser = data.data;
          },
          (error) => {
            console.error('Error fetching fundraisers:', error);
          },
        );
    }
  }

  calculateProgressBarWidth(fundraiser: any): string {
    const percentage =
      (fundraiser.current_amount / fundraiser.target_amount) * 100;
    if (percentage == 0) return '1' + '%';
    return percentage + '%';
  }

  exportExcel() {
    this.loadFlag2 = true;
    const exportUrl =
      AppConstants.BASE_URL +
      '/fundraisers' +
      '/' +
      this.urlParam['fund_id'] +
      '/export';
    this.service.exportToExcel(exportUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 200) {
          this.loadFlag2 = false;
          this.downloadExcel(res);
          this.service.showSnackBar('File is loading', 'ok', 3000);
        } else {
          this.loadFlag2 = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loadFlag2 = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }

  downloadReceipt(paymentId: any) {
    const downloadPDFUrl =
      AppConstants.BASE_URL + '/payment/downloadpdf/' + paymentId;
    this.service.exportToPDF(downloadPDFUrl).subscribe({
      next: (res: any) => {
        if (res) {
          this.blob = new Blob([res], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(res);
          var a = document.createElement('a');
          a.href = downloadURL;
          a.setAttribute('target', '_blank');
          a.click();
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
}
