import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AddPurposeOfDonationDialogComponent } from 'src/app/dialogs/add-purpose-of-donation-dialog/add-purpose-of-donation-dialog.component';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { ViewPurposeOfDonationDialogComponent } from 'src/app/dialogs/view-purpose-of-donation-dialog/view-purpose-of-donation-dialog.component';
import { DonationsComponent } from 'src/app/donations/donations/donations.component';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseListComponent } from 'src/app/shared/abstract/base-list.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { HeaderConstants } from 'src/app/shared/constants/header-constants';
import { PermissionConstants } from 'src/app/shared/constants/permission-constants';

@Component({
  selector: 'app-purpose-of-donation-list',
  templateUrl: './purpose-of-donation-list.component.html',
  styleUrls: ['./purpose-of-donation-list.component.css'],
})
export class PurposeOfDonationListComponent
  extends BaseListComponent
  implements OnInit
{
  public deleteDonationType_url: string;
  constructor(
    datamanagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private donation: DonationsComponent,
  ) {
    super();
    this.page = 1;
    this.rows = 10;
    this.service = datamanagerService;
    this.getTableSettings();
    this.searchHits = new Subject<void>();
    this.getListUrl =
      AppConstants.BASE_URL + AppConstants.DONATIONTYPE_LIST_URL;
    this.deleteDonationType_url =
      AppConstants.BASE_URL + AppConstants.DELETEDONATIONTYPE_URL;
    this.columnDefinition = HeaderConstants.purposeOfDonationsListHeader;

    this.donation.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATIONTYPE_ADD,
    );
    this.donation.delete_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATIONTYPE_REMOVE,
    );
    this.donation.view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATIONTYPE_VIEW,
    );
    this.donation.edit_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATIONTYPE_EDIT,
    );
    this.permissionsCheck = {
      edit_permission: this.donation.edit_permission,
      delete_permission: this.donation.delete_permission,
      view_permission: this.donation.view_permission,
      create_permission: this.donation.create_permission,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
  }

  onNotifySelected(selectedRows: object[]) {
    console.log('selectedRows', selectedRows);
  }
  protected getListRes(res: any): void {
    this.rowData = [];
    if (res['status'] == 'success') {
      this.resultsLength = res.data.pagination.total;
      res.data.rowData.forEach((element: any, index: number) => {
        this.rowData.push(element);
      });
      this.loader = false;
    }
  }

  protected getListErr(err: any): void {
    console.log('err', err);
  }

  getActions(data: any) {
    if (data.actions === 'delete') {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this Donation Type?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.donation_type_id],
          };
          this.service.delete(this.deleteDonationType_url, payload).subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([location.pathname]);
                  });
              } else {
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
        }
      });
    } else if (data.actions === 'edit') {
      const dialogRef = this.dialog.open(AddPurposeOfDonationDialogComponent, {
        data,
        disableClose: true,
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname]);
            });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ViewPurposeOfDonationDialogComponent, {
        data,
        disableClose: true,
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
        }
      });
    }
  }
}
