<meta name="viewport" content="width=device-width, initial-scale=1" />

<body>
    <div class="container">
        <h2 class="checkout-title">Payment Information</h2>
        <form id="checkout-form" (ngSubmit)="onSubmit($event)">
            <div class="form-group">
                <label for="card-number">Card Number</label>
                <div id="card-number" [ngStyle]="{ 'background': cardNumberBackground + ' no-repeat right/10%' }"
                    class="card-input"></div>
                <div id="card-number-error" class="error-message">
                    {{ errorMessages["card-number"] }}
                </div>
            </div>
            <div class="form-group">
                <label for="card-cvv">Card CVV</label>
                <div id="card-cvv" class="card-input"></div>
                <div id="cvv-error-message" class="error-message">
                    {{ errorMessages["cvv"] }}
                </div>
            </div>
            <div class="form-group">
                <label for="card-expiry">Card Expiry</label>
                <div id="card-expiry" class="card-input"></div>
                <div id="expiry-error-message" class="error-message">
                    {{ errorMessages["expiry"] }}
                </div>
            </div>
            <button id="pay-button" [ngClass]="payButtonClass" type="submit">
                {{payWord ?? "Pay"}} ${{ amount??0 }} {{ duration }}
            </button>
            <div id="feedback">
            </div>
            <div class="error">{{ message }}</div>
        </form>

        <div id="processing-screen" *ngIf="processingScreenVisible" class="processing">
            Processing...
        </div>
    </div>
</body>
