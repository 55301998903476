import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseListComponent } from 'src/app/shared/abstract/base-list.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';

import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { HeaderConstants } from 'src/app/shared/constants/header-constants';
import { ViewDiscountDialogComponent } from '../../dialogs/view-discount-dialog/view-discount-dialog.component';

@Component({
  selector: 'app-manage-system-discount',
  templateUrl: './manage-system-discount.component.html',
  styleUrls: ['./manage-system-discount.component.css'],
})
export class ManageSystemDiscountComponent
  extends BaseListComponent
  implements OnInit, DoCheck
{
  public viewData: Object;
  public discountUrl: string =
    AppConstants.BASE_URL + AppConstants.ADD_DISCOUNT;
  public deleteDiscount_url: string;
  public gridData: Object = [];
  public discounttData: Object = [];
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');

  constructor(
    dataManagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
    this.service = dataManagerService;
    this.page = 1;
    this.rows = 10;
    this.getTableSettings();
    this.searchHits = new Subject<void>();
    this.getListUrl = AppConstants.BASE_URL + AppConstants.SYS_DISCOUNT;
    this.deleteDiscount_url = AppConstants.BASE_URL + AppConstants.SYS_DISCOUNT;
    this.columnDefinition = HeaderConstants.discountListHeader;
    this.permissionsCheck = {
      edit_permission: true,
      delete_permission: true,
      view_permission: true,
      create_permission: true,
      excel_permission: true,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    // this.getGridData();
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
    //  throw new Error('Method not implemented.');
  }
  protected getListRes(res: any): void {
    this.loader = true;
    this.rowData = [];
    console.log('res', res);

    if (res['status'] == 'success') {
      this.resultsLength = res.data.total;

      res.data.data.forEach((element: any, index: number) => {
        for (let i in element) {
          if (i === 'type') {
            element['discount_type'] =
              element[i] === 1 ? 'Percentage %' : 'Cash';
            delete element[i];
          } else if (i === 'isActive') {
            element['isActive'] = element[i] === 1 ? 'Active' : 'InActive';
          }
        }
        this.rowData.push(element);
      });
      this.loader = false;
    } else {
      this.loader = false;
    }
  }

  protected getGridData() {
    this.service.getAllDiscounts(this.getListUrl, {}).subscribe({
      next: (res: any) => {
        this.discounttData = res.data.data;
        console.log('====================');
        console.log(JSON.stringify(this.discounttData));
        console.log('====================');
        this.loader = false;
      },
    });
    console.log('====================');
    console.log(JSON.stringify(this.discounttData));
    console.log('====================');
  }
  protected getListErr(err: any): void {
    console.log('err', err);
    this.loader = false;
  }

  ngDoCheck() {
    if (location.pathname === '/all-discounts') {
      this.router.navigate(['/all-discounts/discount']);
    }
    if (this.create_permission) {
      // this.create = this.create_permission;
    }
  }
  createNew() {
    this.router.navigate(['/add-discount'], {});
  }
  getActions(data: any) {
    if (data.actions === 'delete') {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this Discount?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.id],
          };
          this.service
            .deleteRequest(
              this.deleteDiscount_url + '/' + data.data.id,
              payload,
            )
            .subscribe({
              next: (res: any) => {
                console.log('res', res);
                if (res['status'] == 'success') {
                  this.service.showSnackBar(res.message, 'ok', 3000);
                  this.router
                    .navigateByUrl('home', { skipLocationChange: true })
                    .then(() => {
                      this.router.navigate([location.pathname]);
                    });
                } else {
                  this.service.showSnackBar(res.message, 'ok', 3000);
                }
              },
              error: (error) => {
                this.service.showSnackBar(error.message, 'ok', 3000);
              },
            });
        }
      });
    } else if (data.actions === 'edit') {
      this.router.navigate(['/edit-discount'], {
        queryParams: { id: data.data.id },
      });
    } else {
      console.log('data', data);
      const dialogRef = this.dialog.open(ViewDiscountDialogComponent, {
        data,
        disableClose: true,
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
        }
      });
    }
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
  }
}
