import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../shared/abstract/base.component';
import { DatePipe } from '@angular/common';

import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { AppConstants } from '../shared/constants/app-constants';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-volunteer-page',
  templateUrl: './volunteer-page.component.html',
  styleUrls: ['./volunteer-page.component.css'],
})
export class VolunteerPageComponent extends BaseComponent implements OnInit {
  volunteerForm: any;
  token: string | undefined;

  override headings = 'Volunteer Application';
  formMessage: string;
  isLoading: boolean;
  volunteerUrl: string = AppConstants.BASE_URL + '/volunteer';
  hasIframeParameter: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
  ) {
    super();
    this.service = dataManagerService;
    this.token = undefined;
  }
  days = [
    { value: 1, name: 'Sunday' },
    { value: 2, name: 'Monday' },
    { value: 3, name: 'Tuesday' },
    { value: 4, name: 'Wednesday' },
    { value: 5, name: 'Thursday' },
    { value: 6, name: 'Friday' },
    { value: 7, name: 'Saturday' },
  ];
  weeklyHoursAvailabilityOptions = [
    { value: 1, viewValue: '1 - 5 hrs' },
    { value: 2, viewValue: '5 - 10 hrs' },
    { value: 3, viewValue: '10 - 15 hrs' },
    { value: 4, viewValue: 'More than 15 hrs' },
  ];
  skillSetInterestsOptions = [
    { id: 1, name: 'Teaching Quran & Islamic studies' },
    { id: 2, name: 'Teaching Arabic language' },
    { id: 3, name: 'Management Skills' },
    { id: 4, name: 'Marketing' },
    { id: 5, name: 'Photographing' },
    { id: 6, name: 'Designing' },
    { id: 7, name: 'IT & computer skills' },
    { id: 8, name: 'Programming (Web Dev or Mobile Dev)' },
    { id: 9, name: 'Leading sports activities' },
    { id: 10, name: 'Art' },
    { id: 11, name: 'Teaching cooking and baking' },
    { id: 12, name: 'Teaching sewing, knitting, and/or crocheting' },
    { id: 13, name: 'Photography' },
    { id: 14, name: 'Graphic Design' },
    { id: 15, name: 'Videography' },
    { id: 16, name: 'Media post production' },
    { id: 17, name: 'Other' },
  ];
  selectedDays: string[] = [];

  ngOnInit(): void {
    this.volunteerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      contact_method: ['', [Validators.required]],
      availabilityDays: [[], [Validators.required]],
      weeklyHoursAvailability: [[], [Validators.required]],
      career: [''],
      education: ['', [Validators.required]],
      skillSetInterests: [[], [Validators.required]],
      recaptcha: ['', Validators.required],
      backgroundCheck: ['', Validators.required],
    });
    this.route.queryParams.subscribe((params) => {
      this.hasIframeParameter = params['iframe'] === '1';
    });
  }

  get volunteerFormControls() {
    return this.volunteerForm.controls;
  }
  get availabilityDays() {
    return this.volunteerFormControls.availabilityDays;
  }

  onSubmit() {
    this.submitted = true;
    if (this.volunteerForm.invalid) {
      this.formMessage = 'Please Fill Required Fields';
      return;
    } else {
      this.volunteerForm.value.contact_number =
        this.volunteerForm.value.contact_number.replace(/\D+/g, '');
      this.service
        .create(this.volunteerUrl, this.volunteerForm.value)
        .subscribe({
          next: (res: any) => {
            if (
              res &&
              res['status'] &&
              res['status'] == 'success' &&
              res.data
            ) {
              this.loadFlag2 = false;
              this.service.showSnackBar(res.message, 'ok', 5000);
              this.router.navigate(['/login']);
            } else {
              this.loadFlag2 = false;

              setTimeout(() => {
                // After the operation is complete, hide the spinner
                this.isLoading = false;
              }, 2000);
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = [
                'name',
                'gender',
                'email',
                'contact_number',
                'contact_method',
                'availabilityDays',
                'weeklyHoursAvailability',
                'career',
                'education',
                'skillSetInterests',
                'recaptcha',
                'backgroundCheck',
              ];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
          },
        });
    }
  }

  selectedOptionsNames(): string {
    const selectedIds = this.volunteerForm.get('skillSetInterests').value;
    const selectedNames = selectedIds.map((id: any) => {
      const selectedOption = this.skillSetInterestsOptions.find(
        (option) => option.id === id,
      );
      return selectedOption ? selectedOption.name : '';
    });
    return selectedNames.join(', ') || 'Select skill set and interests';
  }
  selectedDaysNames(): string {
    const selectedIds = this.volunteerForm.get('availabilityDays').value;
    const selectedNames = selectedIds.map((id: any) => {
      const selectedOption = this.days.find(
        (option: any) => option.value === id,
      );
      return selectedOption ? selectedOption.name : '';
    });
    return selectedNames.join(', ') || 'Select Days';
  }

  toggleIframe() {
    return !this.hasIframeParameter;
  }
}
