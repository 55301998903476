<!-- navbar.component.html -->

<nav class="navbar">
    <button class="hamburger-icon" (click)="toggleNav()">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="nav-links" [class.active]="navActive">
        <button routerLink="/fundraisers/home" routerLinkActive="active">
            <mat-icon>home</mat-icon> Home
        </button>
        <a href="https://nsicc.ca/contact/" target="_blank" class="external-link">
            <button>
                <mat-icon>mail</mat-icon> Contact
            </button>
        </a>
    </div>
    <div class="logo">
        <img (click)="navigateTo('/login')" src="../../assets/landinglogo.png" style="width:90px;height:90px"
            alt="Logo">
    </div>

    <div class="nav-links mr-1" [class.active]="navActive">
        <a href="https://nsicc.ca/about/" target="_blank" class="external-link">
            <button>
                <mat-icon>info</mat-icon> About
            </button>
        </a>
    </div>
    <div class="login-btn nav-links " [class.active]="navActive">
        <button (click)="navigateTo('/login')">
            <mat-icon>account_circle</mat-icon> {{ userName !=null ? userName : 'Login'}}
        </button>
    </div>
</nav>