<div class="component-container">

    <!-- Navbar -->
    <app-fund-navbar></app-fund-navbar>
    <div class="container">
        <div class="current-fundraisers row" *ngIf="fundraiser">

            <div class="fundraiser-card">
                <button class="btn btn-primary" style="max-width:90px;" [routerLink]="['/fundraisers/home']"
                    name="back"><i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                <img class="fundraiser-image" [src]="fundraiser?.cover_image" alt="{{ fundraiser?.title }}" />
                <div>
                    <h2>{{ fundraiser?.title }}</h2>
                    <div class="fundraiser-description">
                        <p>{{ fundraiser?.description }}</p>
                    </div>

                    <div class="progress-bar">
                        <div class="fill" [style.width]="calculateProgressBarWidth(fundraiser)"></div>
                    </div>
                    <div class="fundraiser-amounts">
                        <span style="font-weight:500"> ${{ fundraiser.current_amount }} raised of</span> ${{
                        fundraiser?.target_amount }}
                    </div>
                </div>
                <form [formGroup]="donationForm" *ngIf="fundraiser.status=='active'">
                    <div class=" container  mt-4" id="donate">
                        <div class="row">
                            <h2 class="montserrat bolder pt-4 pb-3 d-flex align-items-center"><span
                                    class="circle mr-3"><span class="title-number">1</span></span>
                                Donation
                            </h2>
                        </div>
                        <fieldset class="col mb-3">
                            <legend class="mb-1"> How much would you like to donate?</legend>
                            <div class="row form-group cashButtonGroup">
                                <div class="col-xl-1  col-md-2 col-sm-2 col-4">
                                    <button class="cashButton" (click)="changeCash(10)" mat-raised-button>$10</button>
                                </div>
                                <div class="col-xl-1 col-sm-2 col-md-2 col-4">
                                    <button class="cashButton" (click)="changeCash(20)" mat-raised-button>$20</button>
                                </div>
                                <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                    <button class="cashButton" (click)="changeCash(50)" mat-raised-button>$50</button>
                                </div>
                                <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                    <button class="cashButton" (click)="changeCash(100)" mat-raised-button>$100</button>
                                </div>
                                <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                    <button class="cashButton" (click)="changeCash(1000)"
                                        mat-raised-button>$1000</button>
                                </div>
                            </div>
                            <div class="row">
                                <mat-form-field appearance='outline' class="centered-input">
                                    <input matInput type="number" name="fund_amount" id="fund_amount"
                                        max="{{ maxAmount }}" [(ngModel)]="amount" formControlName="fund_amount"
                                        (keyup)="minMaxCheck(amount)" autocomplete="off" step="1"
                                        placeholder="$ Amount "
                                        [ngClass]="{ 'is-invalid': submitted && donationFormControl?.fund_amount?.errors }">
                                    <mat-error
                                        *ngIf="submitted && donationFormControl?.fund_amount?.errors  && minError">
                                        Minimum amount is 0.01.
                                    </mat-error>
                                    <mat-error *ngIf="amount>maxAmount">
                                        Maximum amount is ${{ maxAmount }}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="submitted && donationFormControl?.fund_amount?.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="donationFormControl.fund_amount?.errors?.required">Fund amount
                                        is
                                        required.
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="row">
                            <h2 class="montserrat bolder pt-4 pb-3 d-flex align-items-center"><span
                                    class="circle mr-3"><span class="title-number">2</span></span>
                                Your Information
                            </h2>
                        </div>
                        <fieldset class="col mb-3" style="padding:20px">

                            <div class="row">
                                <div class="form-group  col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <mat-form-field appearance='outline' class="form-full-width">
                                        <mat-label for="fname"> First name
                                        </mat-label>
                                        <input matInput type="text" name="fname" id="fname" formControlName="first_name"
                                            maxlength="20" (keypress)="letterOnly($event)">
                                    </mat-form-field>
                                    <div *ngIf="submitted && donationFormControl.first_name.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="donationFormControl.first_name.errors?.required">First Name
                                            is Required
                                            .</div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <mat-form-field appearance='outline' class="form-full-width">
                                        <mat-label for="lname"> Last name
                                        </mat-label>
                                        <input matInput type="text" name="lname" id="lname" formControlName="last_name"
                                            maxlength="20" (keypress)="letterOnly($event)">
                                    </mat-form-field>
                                    <div *ngIf="submitted && donationFormControl.last_name.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="donationFormControl.last_name.errors?.required">Last Name
                                            is Required
                                            .</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <mat-form-field appearance='outline' class="form-full-width">
                                        <mat-label for="email"> Email
                                        </mat-label>
                                        <input matInput type="text" name="email" id="email" formControlName="email"
                                            pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.email.errors }">
                                    </mat-form-field>
                                    <div *ngIf="submitted && donationFormControl.email.errors"
                                        class="invalid-feedback p-0">
                                        <div *ngIf="donationFormControl.email?.errors?.required">Email is
                                            required.
                                        </div>
                                        <div *ngIf="donationFormControl.email?.errors?.pattern">Invaild email.</div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">

                                    <span
                                        class="numberone input-group-addon last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2 pb-2 ">+1</span>
                                    <mat-form-field appearance='outline'
                                        class="form-full-width phone col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10">
                                        <mat-label>Phone Number
                                        </mat-label>
                                        <input matInput placeholder="(000) 000-0000" type="tel" appPhoneMask
                                            maxlength="14" autocomplete="off" name="phone"
                                            formControlName="contact_number" id="phone"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.contact_number.errors }">
                                    </mat-form-field>
                                    <div *ngIf="submitted && donationFormControl?.contact_number?.errors"
                                        class="invalid-feedback p-0 ">
                                        <div class="remove-top"
                                            *ngIf="donationFormControl?.contact_number?.errors?.required">Contact is
                                            required.
                                        </div>
                                        <div *ngIf="donationFormControl?.contact_number?.errors?.pattern">Invalid
                                            contact
                                            number.
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">

                                <div class=" col-12">

                                    <legend>Would you like to receive a tax receipt?<span class="asterix">*</span>
                                    </legend>
                                    <mat-radio-group formControlName="has_tax">
                                        <mat-radio-button class="mr-2" value="1">Yes</mat-radio-button>
                                        <mat-radio-button value="0">No</mat-radio-button>
                                    </mat-radio-group>
                                    <div *ngIf="submitted && donationFormControl.has_tax.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="donationFormControl.has_tax.errors?.required">Please Answer
                                            the
                                            Question
                                            .</div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6" *ngIf="showtoAdmin">
                            <label for="payment_method" class="form-label col-12 p-0 mt-2"> Mode Of Payment
                                <span class="asterix">
                                    *
                                </span></label>
                            <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">

                                <label class="form-label" class="text-muted form-check-label mr-5" for="Cash"
                                    *ngIf="showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="1"
                                        class="filled-in form-check-input" id="payment_method-0"
                                        [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"> <label class="form-label"
                                        for="payment_method-0">Cash
                                    </label>
                                </label>
                                <label class="form-check-label" for="payment_method-4">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="4"
                                        class="filled-in form-check-input" id="payment_method-4"
                                        [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-4">Online </label>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="row" style="align-items:baseline;">
                        <div *ngIf="submitted && donationForm.invalid" class="invalid-feedback ml-2 mb-1">
                            {{ formMessage }}
                        </div>

                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex">
                            <div class="btn-group mr-2">
                                <button (click)="onSubmit()" class="btn btn-primary pt-2 ml-2" name="save">Checkout
                                    <i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                        </div>
                    </div>
                </form>

                <div *ngIf="fundraiser.status=='completed'">
                    <div class="alert alert-warning mt-2" role="alert">
                        Congratulations! 🎉 The fundraiser has successfully reached its goal amount. Thank you for your
                        support!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>