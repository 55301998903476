import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-shcool-payment-dialog',
  templateUrl: './shcool-payment-dialog.component.html',
  styleUrls: ['./shcool-payment-dialog.component.css'],
})
export class ShcoolPaymentDialogComponent
  extends BaseComponent
  implements OnInit
{
  public schoolPaymentForm: FormGroup;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  academicList_url: string;
  start_year: any;
  end_year: any;
  yearList: any[] = [];
  userLevelList: any;
  showtoAdmin: boolean;
  errorMsg: any;
  currentPaymnetMonth: any[] = [];
  paidAmount: any;
  payWord: string = 'Pay ';
  duration: string = 'Per Month';
  type: number;
  token: any;
  amount: any;
  isLoading: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ShcoolPaymentDialogComponent>,
  ) {
    super();
    this.service = dataManagerService;
    for (var i = 0; i < 10; i++) {
      this.years.push(new Date().getFullYear() + i);
    }
    this.academicList_url =
      AppConstants.BASE_URL + AppConstants.ADDACADEMIC_URL;
    if (this.dialogData) {
      if (this.dialogData.data.data.registration_paid == 0) {
        this.paidAmount = this.dialogData.data.data.registration_amount;
        this.type = 0;
      } else {
        this.amount = this.dialogData.data.data.amount;
        this.paidAmount =
          this.dialogData.data.data.amount * this.dialogData.data.data.months -
          this.dialogData.data.data.cash_amount;
        this.type = 1;
      }
      this.formLoader = true;
      var currentPaymentMonth: string = '';
      if (this.dialogData.Header != 'Weekend School Payment') {
        currentPaymentMonth =
          AppConstants.BASE_URL +
          '/WeekdaySchoolRegistration/getCurrentPayment/';
      } else {
        currentPaymentMonth =
          AppConstants.BASE_URL + '/schoolregistration/getCurrentPayment/';
      }
      this.getUserLevel();
      this.headings = this.dialogData.Header;
      this.buttonText = 'Proceed';
    }
  }
  ngOnInit(): void {
    this.setSchoolPaymentForm();
  }
  get schoolPaymentFormControl() {
    return this.schoolPaymentForm.controls;
  }
  setSchoolPaymentForm() {
    this.schoolPaymentForm = this.formBuilder.group({
      payment_method: ['', [Validators.required]],
      payment_amount: [''],
      payment_type: [''],
      payment_frequency: ['1'],
      cash_amount: [],
    });
  }

  onSubmit(token: any) {
    this.token = token;

    if (this.dialogRef.getState() == 0) {
      this.submitted = true;
      if (this.schoolPaymentForm.invalid) {
        return;
      } else {
        this.loadFlag2 = true;
        this.isLoading = true;
        this.schoolPaymentForm.value.payment_token = this.token;
        this.schoolPaymentForm.value.payment_type = this.type;
        this.service
          .create(this.dialogData.url, this.schoolPaymentForm.value)
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success' && typeof res.data != 'string') {
                this.loadFlag2 = false;
                if (this.schoolPaymentForm.value.payment_method === '4') {
                  if (this.schoolPaymentForm.value.payment_frequency == '1') {
                    console.log('Path: One time payment');
                    this.dialogRef.close(res.data);
                  } else if (
                    this.schoolPaymentForm.value.payment_frequency == '4'
                  ) {
                    console.log('Path: Monthly payment');
                    this.dialogRef.close(false);
                  } else {
                    console.log('Path: dunno');
                    this.dialogRef.close(true);
                    this.router.navigate(['../payment'], {
                      queryParams: {
                        id: res.payment.id,
                      },
                      relativeTo: this.route,
                      skipLocationChange: true,
                    });
                  }
                } else {
                  this.dialogRef.close(false);
                }
              } else if (res && res['status'] && res['status'] == 'Denied') {
                this.service.showSnackBar(
                  'Transaction Denied Please Try Again',
                  'ok',
                  3000,
                );
                this.dialogRef.close(false);

                this.router.navigate(['../payment'], {
                  queryParams: {
                    id: res.payment.id,
                  },
                  relativeTo: this.route,
                  skipLocationChange: true,
                });

                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
              } else if (res && res['status'] && res['status'] == 'error') {
                this.service.showSnackBar(res.message, 'ok', 4000);
                this.dialogRef.close(false);

                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
                this.router.navigate(['./']);
              } else if (res['status'] == 'success' && !res.data) {
                this.loadFlag2 = false;
                this.dialogRef.close(false);
                this.service.showSnackBar(res.message, 'ok', 3000);
              } else {
                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
                this.loadFlag2 = false;
                this.errorMsg = res.message;
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              setTimeout(() => {
                // After the operation is complete, hide the spinner
                this.isLoading = false;
              }, 2000);
              this.loadFlag2 = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      }
    } else {
      this.dialogRef.close(false);
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  getUserLevel() {
    this.formLoader = true;
    const levelUrl = AppConstants.BASE_URL + '/groups/' + this.loged_user.gid;
    this.service.getById(levelUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.userLevelList = res.data.row;
          if (
            this.userLevelList.level == 1 ||
            this.userLevelList.level == 2 ||
            this.userLevelList.level == 4
          ) {
            this.showtoAdmin = true;
          } else {
            this.showtoAdmin = false;
          }
          this.formLoader = false;
        } else {
          this.formLoader = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  changeName(data: string) {
    if (data === 'Online') {
      this.buttonText = 'Proceed To Checkout';
      this.schoolPaymentForm.controls['payment_frequency'].setValidators([
        Validators.required,
      ]);
      this.schoolPaymentForm.controls[
        'payment_frequency'
      ].updateValueAndValidity();
    } else {
      this.schoolPaymentForm.controls['payment_frequency'].clearValidators();
      this.schoolPaymentForm.controls[
        'payment_frequency'
      ].updateValueAndValidity();
      this.buttonText = 'Proceed';
    }
  }
}
