import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseListComponent } from '../shared/abstract/base-list.component';
import { AppConstants } from '../shared/constants/app-constants';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy/MM/dd',
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'yyyy/MM/dd',
    dateA11yLabel: 'yyyy/MM/dd',
    monthYearA11yLabel: 'yyyy/MM/dd',
  },
};
@Component({
  selector: 'app-school-payment-export',
  templateUrl: './school-payment-export.component.html',
  styleUrls: ['./school-payment-export.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class SchoolPaymentExportComponent
  extends BaseListComponent
  implements OnInit
{
  constructor(
    private datePipe: DatePipe,
    datamanagerService: DataManagerService,
  ) {
    super();
    this.headings = 'School Reports';
    this.service = datamanagerService;
    this.filterValue = true;
  }
  getListErr() {}
  getListRes() {}
  ngOnInit(): void {
    console.log('ngOnInit');
  }
  exportPaymentData(schoolType: number) {
    this.loadFlag2 = true;
    const exportUrl =
      AppConstants.BASE_URL +
      '/school-report/' +
      schoolType +
      '/export?' +
      (this.filterValue
        ? (!this.isNullOrUndefined(this.reportFromDate)
            ? 'fromDate=' + this.reportFromDate
            : ' ') +
          (!this.isNullOrUndefined(this.reporttoDate)
            ? '&toDate=' + this.reporttoDate
            : '')
        : '') +
      (this.isNullOrUndefined(this.filterUrl)
        ? ''
        : '&search=' + this.filterUrl);
    this.service.exportToExcel(exportUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 200) {
          this.loadFlag2 = false;
          this.downloadExcel(res);
          this.service.showSnackBar('File is loading', 'ok', 3000);
        } else {
          this.loadFlag2 = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loadFlag2 = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  dateChange(key: string, event: any) {
    if (key == 'fromDate') {
      const fromDate = this.datePipe.transform(
        this.reportFromDate,
        'yyyy-MM-dd',
      );
      this.reportFromDate = fromDate;
    } else {
      const todate = this.datePipe.transform(this.reporttoDate, 'yyyy-MM-dd');
      this.reporttoDate = todate;
    }
    this.setUrl();
  }
}
