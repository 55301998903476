import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/shared/abstract/base.component';

@Component({
  selector: 'app-view-upcomingevent-dialog',
  templateUrl: './view-upcomingevent-dialog.component.html',
  styleUrls: ['./view-upcomingevent-dialog.component.css'],
})
export class ViewUpcomingeventDialogComponent
  extends BaseComponent
  implements OnInit
{
  public typename: string;
  public viewUpcomingData: any;
  id: any;
  safeHtml: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ViewUpcomingeventDialogComponent>,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
  ) {
    super();
    if (this.dialogData) {
      console.log('this.dialogData.data', this.dialogData.data);
      this.dialogData.data.viewAppointments.forEach((data: any) => {
        if (data.appointmentData) {
          this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
            data.appointmentData.description,
          );

          this.viewUpcomingData = data.appointmentData;
          this.id = this.viewUpcomingData.id;
          if (this.viewUpcomingData.startDate) {
            this.viewUpcomingData.startDate = this.datePipe.transform(
              this.viewUpcomingData.startDate,
              'yyyy-MM-dd',
            );
          }
          if (this.viewUpcomingData.endDate) {
            this.viewUpcomingData.endDate = this.datePipe.transform(
              this.viewUpcomingData.endDate,
              'yyyy-MM-dd',
            );
          }
          if (this.viewUpcomingData.repeat === 2) {
            this.viewUpcomingData.recurring =
              this.viewUpcomingData.recurring == 1
                ? 'Daily'
                : this.viewUpcomingData.recurring == 2
                  ? 'Weekly'
                  : this.viewUpcomingData.recurring == 3
                    ? 'Monthly'
                    : 'Yearly';
          } else if (this.viewUpcomingData.repeat === 1) {
            this.viewUpcomingData.recurring = 'One Time';
          }
          if (
            this.viewUpcomingData.phone &&
            this.viewUpcomingData.phone.indexOf('+1') <= -1
          ) {
            const phone = this.viewUpcomingData.phone.match(
              /(\d{3})(\d{3})(\d{4})/,
            );
            this.viewUpcomingData.phone =
              '+1 ' + '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
          }
          if (this.viewUpcomingData.type == 2) {
            this.viewUpcomingData.cost = Number(
              this.viewUpcomingData.cost,
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialogRef.close(false);
      }
    });
  }
  close() {
    this.dialogRef.close(false);
  }

  goToEventReg() {
    this.dialogRef.close(true);
  }
}
