<div class="landing m-xl-4 m-lg-4 m-md-4">
    <div class="container w-full h-full">
        <div class="row justify-content-center">
            <div class="col-md-12 col-12 col-sm-12 col-xl-12 col-lg-12 p-0 text-center login-form">
                <img src="../../../assets/landing-logo.svg" class="mb-3 text-center" />
                <div class="col-12 text-center mb-2">
                    <img src="../../../assets/login-logo.png" width="140px" class="text-center mb-3" />
                    <h1 class="text-center landing-main m-xl-4 m-lg-4 m-md-4">Welcome to the Nova Scotia Islamic Community Centre /<br>
                        Kearney Lake Masjid.
                    </h1>
                    <p class="text-muted landing-hint m-xl-4 m-lg-4 m-md-4">You can navigate using any of the left menu options depending
                        on the
                        service or <br> program you are interested in. </p>
                    <p class="landing-support"><em>Thank you for your continuous support, the Masjid needs your help as
                            always.<br> Please
                            continue donating and supporting the Masjid.</em> </p>
                </div>
            </div>
        </div>
    </div>
</div>
