<div class="centered-container">
    <mat-card class="cardClass" appearance="outlined">
        <button class="btn btn-primary" style="width:80px;" [routerLink]="['/fundraisers']" name="back"><i
                class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
        <mat-toolbar>
            <mat-toolbar-row class="title-header">

                <h1>{{ formTitle }}</h1>
            </mat-toolbar-row>

        </mat-toolbar>
        <h5 class="step-word"> Step {{ currentStep }} of 3</h5>

        <div class="step-bar mt-2">
            <div class="step" [class.active]="currentStep >= 1"></div>
            <div class="step" [class.active]="currentStep >= 2"></div>
            <div class="step" [class.active]="currentStep >= 3"></div>
        </div>

        <div *ngIf="!formLoader; else showLoader">
            <!-- Step 1 -->
            <div *ngIf="currentStep === 1" class="mt-4">
                <form [formGroup]="stepOneForm">
                    <div class="row">
                        <h2 class="info">Let’s start with the basics
                        </h2>
                    </div>
                    <div class="row">
                        <label for="title" class="form-label">What is the title of your fundraiser?
                        </label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="title" placeholder="Title">
                        </mat-form-field>
                        <div *ngIf="formOneSubmit && stepOneForm.controls.title.errors"
                            class="invalid-feedback remove-upperspace mb-2">
                            <div *ngIf="stepOneForm.controls.title?.errors?.required">
                                Title is required.
                            </div>
                        </div>

                        <label for="description" class="form-label">Provide a description for your fundraiser:</label>
                        <mat-form-field appearance="outline">
                            <textarea matInput formControlName="description" placeholder="Description"></textarea>
                        </mat-form-field>
                        <div *ngIf="formOneSubmit && stepOneForm.controls.description.errors"
                            class="invalid-feedback remove-upperspace mb-2">
                            <div *ngIf="stepOneForm.controls.description?.errors?.required">
                                Description is required.
                            </div>
                        </div>
                        <label for="fund_slug" class="form-label">Choose a unique page address for your
                            fundraiser:</label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="fund_slug" placeholder="Fundraiser Slug"
                                [ngClass]="{ 'is-invalid': submitted && stepOneForm.controls.fund_slug.errors }"
                                maxlength="40">

                        </mat-form-field>
                        <div *ngIf="formOneSubmit && stepOneForm.controls.fund_slug.errors"
                            class="invalid-feedback remove-upperspace">
                            <div *ngIf="stepOneForm.controls.fund_slug?.errors?.required">
                                Page Address is required.
                            </div>

                        </div>
                        <div *ngIf="stepOneForm.get('fund_slug').hasError('invalidSlug')"
                            class="invalid-feedback  remove-upperspace" style="font-size:11px">
                            Special characters and spaces are not allowed. Please use letters, numbers, and hyphens
                            only.
                        </div>
                    </div>


                </form>
                <div>
                    <button class="btn nextButton full-width-button" (click)="nextStep()">Next</button>
                </div>
            </div>

            <!-- Step 2 -->
            <div *ngIf="currentStep === 2" class="mt-4">
                <form [formGroup]="stepTwoForm" class="mb-4">
                    <div class="row">
                        <h2 class="info">Set your fundraising target
                        </h2>
                    </div>
                    <div class="row">
                        <label for="title" class="form-label">How much would you like to raise?
                        </label>
                        <div class="input-group-prepend">
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" class="form-control" placeholder="0" formControlName="target_amount">
                            <div *ngIf="formTwoSubmit && stepTwoForm.controls.target_amount.errors"
                                class="invalid-feedback">
                                <div *ngIf="stepTwoForm.controls.target_amount?.errors?.required">
                                    Target Amount is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <label for="title" class="form-label">Which account would you like to use for
                            fundraising?</label>
                        <mat-form-field class="centered-input">
                            <select matNativeControl aria-label="Default select example"
                                [ngModelOptions]="{ updateOn: 'submit' }" formControlName="bank_account"
                                id="bank_account"
                                [ngClass]="{ 'is-invalid': submitted && donationFormControl.bank_account.errors }">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let data of expenseAccountList" [ngValue]="data.id">
                                    {{data.account_number}}
                                </option>
                            </select>
                            <div *ngIf="submitted && donationFormControl.bank_account.errors"
                                class="invalid-feedback p-0">
                                <div *ngIf="donationFormControl.bank_account?.errors?.required">Account number
                                    is
                                    required.
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                </form>
                <div>
                    <button class="btn backButton" (click)="previousStep()">Back</button>
                    <button class="btn nextButton" (click)="nextStep()">Next</button>
                </div>
            </div>

            <!-- Step 3 -->
            <div *ngIf="currentStep === 3" class="mt-4">
                <form [formGroup]="stepThreeForm">
                    <div class="row">
                        <h2 class="info">
                            Add a cover photo
                        </h2>
                    </div>

                    <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                        <label class="form-label" for="thumbnail">Cover Image <span class="asterix"> *</span></label>
                        <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                            <label class="form-label btn btn-default p-0 col-12 mb-0">
                                <input type="file" formControlName="cover_image" id="thumbnail" class="form-control"
                                    accept="image/*" (change)="selectThumnailImage($event)"
                                    [ngClass]="{ 'is-invalid': submitted && stepThreeForm.controls.cover_image.errors }" />
                            </label>
                            <div class="col-12 text-center mt-2" *ngIf="thumnailPreview">
                                <img [src]="thumnailPreview" class="preview"
                                    [ngStyle]="{'max-width.px': cardMaxWidth, 'max-height.px': cardMaxHeight}" />
                            </div>
                            <div *ngIf="fileError" class="invalid-feedback">The maximum size for the cover image upload
                                is 5MB.</div>
                            <div *ngIf="submitted && stepThreeForm.controls.cover_image.errors"
                                class="invalid-feedback">
                                <div *ngIf="stepThreeForm.controls.cover_image.errors.required">Cover image is required.
                                </div>
                                <div *ngIf="stepThreeForm.controls.cover_image.errors.dimensions">Image dimensions must
                                    be within 300x200 pixels.</div>

                            </div>
                        </div>
                    </div>

                </form>
                <div>
                    <button class="btn backButton" (click)="previousStep()">Back</button>
                    <button class="btn nextButton" (click)="submitForm()">{{ buttonText }}</button>
                </div>
            </div>
        </div>
        <ng-template #showLoader>
            <div class="text-center loader mt-4 mb-4">
                <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                </mat-progress-spinner>
            </div>
        </ng-template>
    </mat-card>
</div>