import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { MustMatch } from 'src/app/shared/constants/custom.validation';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  public resetPasswordForm!: FormGroup;
  public resetPasswordUrl = AppConstants.BASE_URL + '/user/doreset/';
  errorMsg = '';
  id: string;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private dataManagerService: DataManagerService,
  ) {
    super();
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.id = e.url;
      }
    });
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]],
        mobile: true,
      },
      {
        validator: MustMatch('password', 'password_confirmation'),
      },
    );
  }
  get resetPasswordFormControl() {
    return this.resetPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.loadFlag2 = true;
      this.dataManagerService
        .userSignup(
          this.resetPasswordUrl + this.id.split('/')[2],
          this.resetPasswordForm.value,
        )
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              this._router.navigate(['/login']);
            } else {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = ['passwrd', 'password_confirmation'];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
}
