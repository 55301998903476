<div class="jumbotron">
    <div class="container w-full h-full p-0">
        <div class="row justify-content-center">
            <div class="col-md-12 login-form p-0 p-sm-3">
                <div class="col-12 text-center mb-2">
                    <img src="../../../assets/login-logo.png" width="140px" class="text-center" />
                    <h1 class="text-center mt-2">Forgot Password</h1>
                    <span class="text-muted">Reset your account password</span>
                </div>
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="text-muted" for="email">Email<span class="asterix"> * </span></label>
                        <input type="text" id="email" name="email" formControlName="credit_email" class="form-control"
                            pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            [ngClass]="{ 'is-invalid': submitted && forgotFormControl.credit_email.errors }" />
                        <div *ngIf="submitted && forgotFormControl.credit_email.errors" placeholder="Username"
                            class="invalid-feedback">
                            <div *ngIf="forgotFormControl.credit_email.errors.required">Email is required.</div>
                            <div *ngIf="forgotFormControl.credit_email.errors.pattern">Invaild email.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="col-12 btn btn-primary">Submit <i *ngIf="loadFlag2"
                                class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                    </div>
                    <div class="form-group text-center">
                        <span class="text-muted">Already have an account? <a [routerLink]="['/login']">Login</a></span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
