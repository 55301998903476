<mat-card appearance="outlined" class="">

    <h1 class="text-center">Welcome to Our School</h1>

    <section class="description">
        <article>
            <h2>About Our School</h2>
            <p>Welcome to our school! We offer various programs and courses designed to cater to different educational
                needs. Below are the details of our available pages:</p>
        </article>

        <article class="page-info">
            <h3><a href="schools/weekendschoolregistration">Weekend School Registration Page</a></h3>
            <p>Our Weekend School Registration Page allows you to enroll your child in weekend classes designed to
                complement their regular school education.</p>
        </article>

        <article class="page-info">
            <h3><a href="schools/elfurqanhifdh">El Furqan Hafiz Program Registration Page</a></h3>
            <p>Join our El Furqan Hafiz Program, a special initiative for memorizing the Holy Quran. Register through
                this page to be a part of this spiritually enriching program.</p>
        </article>

        <article class="page-info">
            <h3><a href="schools/courses">All Courses Page</a></h3>
            <p>Explore a comprehensive list of all the courses we offer, including details about each course's
                curriculum, timings, and instructors.</p>
        </article>

        <article class="page-info">
            <h3><a href="schools/childdetails">List of Enrolled Students</a></h3>
            <p>Access the list of enrolled students, which includes information about your own child's enrollment
                status.
            </p>
        </article>
    </section>
</mat-card>