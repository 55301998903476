<div class="jumbotron">
    <div class="container w-full h-full p-0">
        <div class="row justify-content-center">
            <div class="col-md-12 p-0">
                <div class="col-12 text-center mb-2">
                    <img src="../../../assets/login-logo.png" width="140px" class="text-center" />
                    <h1 class="text-center mt-2">Sign Up</h1>
                    <span class="text-muted">Create your account</span>
                </div>
                <ng-container *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                        <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                            <p class="col-md-12 error text-center" id="err">{{value}}</p>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error">
                        {{errorMsg}}
                    </div>
                </div>
                <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                    <div class="row justify-content-around">
                        <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                            <label class="form-label text-muted" for="firstname">First name <span class="asterix"> *
                                </span></label>
                            <input type="text" id="firstname" formControlName="firstname" class="form-control"
                                maxlength="50" (keypress)="letterOnly($event)"
                                [ngClass]="{ 'is-invalid': submitted && signupFormControl.firstname.errors }"
                                minlength="2" />
                            <div *ngIf="submitted && signupFormControl.firstname.errors" placeholder="firstname"
                                class="invalid-feedback">
                                <div *ngIf="signupFormControl.firstname.errors.required">First name is required.</div>
                                <div *ngIf="signupFormControl.firstname.errors.pattern">Invalid first name.</div>
                                <div *ngIf="signupFormControl.firstname.errors.minlength">First name must be atleast two
                                    chars.</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                            <label class="form-label text-muted" for="lastname">Last name <span class="asterix"> *
                                </span></label>
                            <input type="text" id="lastname" formControlName="lastname" class="form-control"
                                (keypress)="letterOnly($event)" minlength="2" maxlength="50"
                                [ngClass]="{ 'is-invalid': submitted && signupFormControl.lastname.errors }" />
                            <div *ngIf="submitted && signupFormControl.lastname.errors" placeholder="lastname"
                                class="invalid-feedback">
                                <div *ngIf="signupFormControl.lastname.errors.required">Last name is required.</div>
                                <div *ngIf="signupFormControl.lastname.errors.pattern">Invalid last name.</div>
                                <div *ngIf="signupFormControl.lastname.errors.minlength">Last name must be atleast two
                                    chars.</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                        <label class="form-label text-muted" for="email">Email<span class="asterix"> * </span></label>
                        <input type="text" id="email" formControlName="email" class="form-control"
                            pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            [ngClass]="{ 'is-invalid': submitted && signupFormControl.email.errors }" />
                        <div *ngIf="submitted && signupFormControl.email.errors" placeholder="email"
                            class="invalid-feedback">
                            <div *ngIf="signupFormControl.email.errors.required">Email is required.</div>
                            <div *ngIf="signupFormControl.email.errors.pattern">Invalid email.</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                            <label class="form-label text-muted" for="password">Password<span class="asterix"> *
                                </span></label>
                            <input type="password" id="password" formControlName="password" class="form-control"
                                minlength="6"
                                [ngClass]="{ 'is-invalid': submitted && signupFormControl.password.errors }" />
                            <i class="fa fa-eye toggle" id="togglePassword" (click)="toggleViewMode('password')"
                                style="cursor: pointer;"></i>
                            <div *ngIf="submitted && signupFormControl.password.errors" class="invalid-feedback">
                                <div *ngIf="signupFormControl.password.errors.required">Password is required.</div>
                                <div *ngIf="signupFormControl.password.errors.minlength">The password must be atleast 6
                                    chars.</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                            <label class="form-label text-muted" for="confirm_password">Confirm password<span
                                    class="asterix"> * </span></label>
                            <input type="password" id="confirm_password" formControlName="confirm_password"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && signupFormControl.confirm_password.errors }" />
                            <i class="fa fa-eye cptoggle" id="cPtogglePassword" (click)="toggleViewMode('cPassword')"
                                style="cursor: pointer;"></i>
                            <div *ngIf="submitted && signupFormControl.confirm_password.errors"
                                class="invalid-feedback">
                                <div *ngIf="signupFormControl.confirm_password.errors.required">Confirm password is
                                    required.
                                </div>
                                <div *ngIf="signupFormControl.confirm_password.errors.mustMatch">Passwords must match.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
                            <p class="privacy mb-0 text-center">By creating an account, you agree with our <a
                                    href="https://nsicc.ca/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
                        </div>
                    </div>
                    <div class="form-group col-12 p-0">
                        <button class="col-12 btn btn-primary">Sign Up <i *ngIf="loadFlag2"
                                class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                    </div>
                    <div class="form-group text-center col-12">
                        <span class="text-muted">Already have an account? <a [routerLink]="['/login']">Login</a></span>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
