<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="card">
    <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3">
        <div class="form-ajax-box">
            <ng-container *ngIf="topErrorMessage">
                <div class="row err" *ngFor="let obj of keys1 ">
                    <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                        <p class="col-md-12 error text-center" id="err">{{value}}</p>
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngIf="!topErrorMessage">
                <div class="col-md-12 error text-center">
                    {{errorMsg}}
                </div>
            </div>
            <div class="toolbar-nav">
                <div class="row">
                    <mat-toolbar class="main-header mb-2">
                        <mat-toolbar-row>
                            <span>{{ headings }}</span>
                            <span class="example-spacer"></span>

                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div class="event-container">
                        <div class="event-info">
                            <h3>{{ data?.course_title}}</h3>
                            <div class="row">
                                <div *ngIf="data?.thumbnail != null && data?.thumbnail !== '' "
                                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <img class="event-image" src="{{data?.thumbnail}}">
                                </div>
                            </div>
                            <div class="row">
                                <h4 class=""><strong> Description: </strong></h4>
                                <div [innerHTML]="data?.description"></div>
                            </div>
                            <div class="row mt-2" *ngIf="data?.course_completed == 0">
                                <button class="btn btn-buy-now" [disabled]="loadFlag2" type="button" id="13"
                                    (click)="registerCourse()">Enroll
                                    Now <i *ngIf="loadFlag2"
                                        class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="row mt-2 course-message p-0" *ngIf="data?.course_completed == 1">
                                {{data.message}}
                            </div>


                        </div>
                    </div>
                </div>

                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">

                    <mat-card appearance="outlined" class="row mt-3 course-details ">

                        <div class="table-title">Course Details</div>
                        <table>
                            <tr>
                                <th>Title:</th>
                                <td>{{ data?.course_title }}</td>
                            </tr>
                            <tr>
                                <th>Duration:</th>
                                <td>{{ data?.course_duration + " days" }}</td>
                            </tr>
                            <tr>
                                <th>Date:</th>
                                <td><span class="discount-range">{{ data?.course_start_date + " To " +
                                        data?.course_end_date }}</span></td>
                            </tr>
                            <tr>
                                <th>Language:</th>
                                <td *ngIf="data?.language?.length > 0">
                                    <span class="d-flex align-items-center">{{
                                        data?.language.toString().replaceAll(',', ', ') }}</span>

                                </td>
                            </tr>
                            <tr>
                                <th>Category:</th>
                                <td>{{ data?.category.category_name }}</td>
                            </tr>
                            <tr>
                                <th>Instructor:</th>
                                <td>{{ data?.instructor.username }}</td>
                            </tr>
                            <tr>
                                <th>Total Cost:</th>
                                <td><span class="event-cost">{{ data?.course_type == 2 ? (data?.course_fee) :
                                        "Free" }}</span></td>
                            </tr>
                        </table>
                    </mat-card>
                    <mat-card appearance="outlined" class="row mt-3 shadowbox">
                        <h4 class=" mt-3 "><strong>Requirements:</strong></h4>
                        <ul class="requirements ml-4">
                            <li *ngFor="let requirements of data?.requirements">
                                {{ requirements?.requirements}}
                            </li>
                        </ul>
                    </mat-card>
                    <mat-card appearance="outlined" class="row mt-3 shadowbox">
                        <h4 class=" mt-3 "> <strong> Outcomes:</strong></h4>
                        <ul class="outcomes ml-4">
                            <li *ngFor="let outcomes of data?.outcomes">
                                {{ outcomes?.outcomes}}
                            </li>
                        </ul>
                    </mat-card>
                    <div class="course-container">
                        <div>


                        </div>
                    </div>
                </div>

            </div>
            <ng-template #showLoader>
                <div class="text-center loader">
                    <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                    </mat-progress-spinner>
                </div>
            </ng-template>

        </div>
    </div>
</div>