import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-expense-account-dialog',
  templateUrl: './view-expense-account-dialog.component.html',
  styleUrls: ['./view-expense-account-dialog.component.css'],
})
export class ViewExpenseAccountDialogComponent implements OnInit {
  public account_number: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ViewExpenseAccountDialogComponent>,
  ) {
    if (this.dialogData) {
      console.log('this.dialogData', this.dialogData);
      this.account_number = this.dialogData.data.account_number;
    }
  }

  ngOnInit(): void {
    console.log('ngOnInit');
  }
  close() {
    this.dialogRef.close(true);
  }
}
