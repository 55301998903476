<div class="row title justify-content">

    <mat-card-title class="mat-card-title text-lg-center text-xl-center"> {{ headings }}</mat-card-title>

</div>
<mat-card appearance="outlined" class="view-page mat-card mat-focus-indicator p-0 mx-3 ">
    <div class="card ">

        <div class="card-body">
            <div class="container">
                <div class="col-12 col-lg-12 col-xl-12 col-md-12 p-0 col-sm-12">
                    <h1 class="title">View Volunteer Details</h1>
                </div>
                <div class="table-container">
                    <table>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{{ data.name }}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td>{{ data.gender }}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{ data.email }}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{{ data.contact_number }}</td>
                            </tr>
                            <tr>
                                <th>Prefered Contact Method</th>
                                <td>{{ data.contact_method }}</td>
                            </tr>
                            <tr>
                                <th>Career</th>
                                <td>{{ data.career }}</td>
                            </tr>
                            <tr>
                                <th>Education</th>
                                <td>{{ data.education }}</td>
                            </tr>
                            <tr>
                                <th>Availability Days</th>
                                <td>{{ data.availabilityDays }}</td>
                            </tr>
                            <tr>
                                <th>Weekly Hours Availability</th>
                                <td>{{ data.weeklyHoursAvailability }}</td>
                            </tr>
                            <tr>
                                <th>Skills and interests</th>
                                <td>{{ data.skillSetInterests }}</td>
                            </tr>
                            <tr>
                                <th>Vulnerable Sector Check And Child Abuse Register Check</th>
                                <td>{{ data.backgroundCheck }}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-card>