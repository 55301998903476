import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';
@Component({
  selector: 'app-anonymous-donation',
  templateUrl: './anonymous-donation.component.html',
  styleUrls: ['./anonymous-donation.component.css'],
})
export class AnonymousDonationComponent
  extends BaseComponent
  implements OnInit
{
  public donationForm: FormGroup;
  public donationUrl: string;
  public amount: number;
  public recurringOption: String = '';
  public donationAmount: any;
  showDateError: boolean;
  public maxDate: any;
  public endDate: any;
  public minDate: any;
  public formMessage: string;
  public payWord: string = 'Donate';
  token: any;
  public isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
  ) {
    super();
    this.service = dataManagerService;
    this.countryListUrl = AppConstants.BASE_URL + '/country';
    this.stateListUrl = AppConstants.BASE_URL + '/getState';
    this.cityListUrl = AppConstants.BASE_URL + '/getCity';
    this.donationUrl = AppConstants.BASE_URL + '/anonymous-donation';
    this.getDonationType();
    this.headings = 'DONATE ONLINE (التبرع من خلال الانترنت)';
    this.buttonText = 'Submit';
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.endDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.maxDate = new Date(currentYear + 10, 11, 31);
  }
  ngOnInit(): void {
    this.getCountryList();
    this.donationForm = this.formBuilder.group({
      donation_amount: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.min(0.01),
          Validators.max(9999999.99),
        ],
      ],
      donation_type: ['', [Validators.required]],
      fname: [''],
      lname: [''],
      email: ['', [Validators.required]],
      contact_number: [''],
      country: [''],
      state: [''],
      city: [''],
      recurring_count: [''],
      recurring_date: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      payment_frequency: [''],
      recurring_end_date: [''],
      payment_method: ['', [Validators.required]],
      has_tax: ['', [Validators.required]],
    });
  }
  get donationFormControl() {
    return this.donationForm.controls;
  }

  onSubmit(token: any) {
    this.token = token;
    this.submitted = true;
    if (this.donationForm.invalid) {
      this.formMessage = 'Please Fill Required Fields';
      return;
    } else {
      this.isLoading = true;
      this.loadFlag2 = true;
      this.donationForm.value.contact_number =
        this.donationForm.value.contact_number.replace(/\D+/g, '');
      this.donationForm.value.donation_amount = parseFloat(
        this.donationForm.value.donation_amount,
      ).toFixed(2);
      this.donationForm.value.payment_token = this.token;
      if (
        this.donationForm.value.payment_frequency != 1 &&
        this.donationForm.value.payment_method == 4
      ) {
        this.donationUrl =
          AppConstants.BASE_URL + '/recurring-anonymous-donation';
      } else {
        this.donationUrl = AppConstants.BASE_URL + '/anonymous-donation';
      }
      this.service.create(this.donationUrl, this.donationForm.value).subscribe({
        next: (res: any) => {
          if (res && res['status'] && res['status'] == 'success' && res.data) {
            this.loadFlag2 = false;
            this.router.navigate(['../checkout'], {
              queryParams: {
                id: res.data.payable_id,
                type: 'anonymous-donation',
              },
              relativeTo: this.route,
            });
          } else if (
            res &&
            res['status'] &&
            res['status'] == 'success' &&
            !res.data &&
            !res['approve']
          ) {
            this.loadFlag2 = false;
            this.router.navigate(['/login']);
            this.service.showSnackBar(res.message, 'ok', 3000);
          } else if (
            res &&
            res['status'] &&
            res['status'] == 'success' &&
            res['approve'] == 1
          ) {
            this.service.showSnackBar(res.response.messageText, 'ok', 3000);
            this.router.navigate(['../payment'], {
              queryParams: {
                id: res.payment.id,
              },
              relativeTo: this.route,
              skipLocationChange: true,
            });
          } else if (res && res['status'] && res['status'] == 'Denied') {
            this.service.showSnackBar(
              'Transaction Denied Please Try Again',
              'ok',
              3000,
            );
            this.service.showSnackBar(res.response.messageText, 'ok', 3000);

            this.router.navigate(['../payment'], {
              queryParams: {
                id: res.payment.id,
              },
              relativeTo: this.route,
              skipLocationChange: true,
            });
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
          } else if (res && res['status'] && res['status'] == 'error') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
            this.router.navigate(['/login']);
          } else {
            this.loadFlag2 = false;

            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
            this.topErrorMessage = res.message;
            this.keys = Object.keys(this.topErrorMessage);
            this.keys1 = Object.values(this.topErrorMessage);
            let i;
            let j;
            const name = [
              'donation_amount',
              'donation_type',
              'fname',
              'fname',
              'lname',
              'email',
              'contact_number',
              'contact_number',
              'province',
              'city',
              'payment_method',
            ];
            for (i = 0; i < this.keys.length; i++) {
              for (j = 0; j < name.length; j++) {
                if (this.keys[i].match(name[j])) {
                  const documents = document.getElementById(name[j]);
                  if (documents) {
                    documents.style.borderColor = '#a94442';
                    documents.style.display = 'block';
                  }
                }
              }
            }
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.service.showSnackBar(error.message, 'ok', 3000);
          setTimeout(() => {
            // After the operation is complete, hide the spinner
            this.isLoading = false;
          }, 2000);
        },
      });
    }
  }
  cancel() {
    if (this.matchUrl('/donation')) {
      this.router.navigate(['login']);
    }
  }
  getDonationType() {
    this.formLoader = true;
    const getListUrl = AppConstants.BASE_URL + '/donation_type';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.dontationTypeList = res.data;
          this.formLoader = false;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  setFrequencyValidation() {
    console.log(
      'setFrequencyValidation',
      this.donationForm.value.payment_frequency,
    );
    if (this.donationForm.value.payment_frequency != 1) {
      this.donationForm.controls['recurring_date'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['recurring_date'].updateValueAndValidity();
      this.donationForm.controls['recurring_count'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['recurring_count'].updateValueAndValidity();
      setTimeout(() => {
        const contentSection = document.getElementById('notice');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      this.donationForm.controls['recurring_date'].clearValidators();
      this.donationForm.controls['recurring_count'].clearValidators();
      this.donationForm.controls['recurring_date'].updateValueAndValidity();
      this.donationForm.controls['recurring_count'].updateValueAndValidity();
    }
  }

  changeName(data: string) {
    if (data === 'Online') {
      this.buttonText = 'Proceed To Checkout';
      this.donationForm.controls['payment_frequency'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['payment_frequency'].updateValueAndValidity();
      setTimeout(() => {
        const contentSection = document.getElementById('payment_frequency');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      setTimeout(() => {
        const contentSection = document.getElementById('epaymenInfo');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
      this.donationForm.controls['payment_frequency'].clearValidators();
      this.donationForm.controls['payment_frequency'].updateValueAndValidity();
      this.buttonText = 'Save';
    }
  }
  setRecurring(name: string) {
    this.recurringOption = name;
  }
  changeCash(cash: any) {
    this.amount = cash;
  }
  validateDate() {
    const startDate = new Date(
      (
        this.datePipe.transform(this.donationForm.value.rec, 'yyyy-MM-dd') +
        ' ' +
        ''
      ).toString(),
    ).getTime();
    const endDate = new Date(
      (
        this.datePipe.transform(
          this.donationForm.value.recurring_end_date,
          'yyyy-MM-dd',
        ) +
        ' ' +
        ''
      ).toString(),
    ).getTime();
    console.log('date range', startDate, endDate);
    if (startDate > endDate) {
      this.showDateError = true;
    } else {
      this.showDateError = false;
    }
    console.log('showDateError', this.showDateError);
  }
}
