<div class="jumbotron">
  <div class="container w-full h-full p-0">
    <div class="row justify-content-center">
      <div class="col-md-12 login-form p-0 p-sm-3">
        <div class="col-12 text-center mb-2">
          <img src="../../../assets/login-logo.png" width="140px" class="text-center" />
          <h1 class="text-center mt-2">Login</h1>
          <span class="text-muted">Sign in to your account</span>
        </div>
        <div class="alert alert-danger centered-alert" role="alert" *ngIf="errorMessage != '' ">
          <p class="message">{{ errorMessage }}</p>
        </div>
        <div class="alert alert-warning centered-alert" role="alert" *ngIf="returnUrl != '/' ">
          <p class="message"> You need to login or sign up to access this page</p>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="text-muted" for="email">Email<span class="asterix"> * </span></label>
            <input type="text" formControlName="email" class="form-control" name="email" id="email"
              [ngClass]="{ 'is-invalid': submitted && loginFormControl.email.errors }"
              pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
            <div *ngIf="submitted && loginFormControl.email.errors" placeholder="Username" class="invalid-feedback">
              <div *ngIf="loginFormControl.email.errors.required">Email is required.</div>
              <div *ngIf="loginFormControl.email.errors.pattern">Invaild email.</div>
            </div>
          </div>
          <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
            <label class="text-muted" for="password">Password<span class="asterix"> * </span></label>
            <input type="password" formControlName="password" name="password" id="password" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && loginFormControl.password.errors }" />
            <i class="fa fa-eye toggle" id="togglePassword" (click)="toggleViewMode('password')"
              style="cursor: pointer;"></i>
            <div *ngIf="submitted && loginFormControl.password.errors" class="invalid-feedback">
              <div *ngIf="loginFormControl.password.errors.required">Password is required.</div>
            </div>
          </div>
          <div class="row pl-2">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
              <!-- <input id="remember-me" name="remember-me" type="checkbox" class="form-check-input" value="rememberMe">
              <label for="remember-me" class="text-muted">Remember me</label> -->
            </div>
            <div class="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6 pr-0 text-end">
              <a [routerLink]="['/forgot']" class="text-muted">Forgot Password?</a>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12 p-0">
              <p class="privacy mb-0 text-center">By signing in, you agree with our <a
                  href="https://nsicc.ca/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
            </div>
          </div>
          <div class="form-group text-center">
            <button class="btn btn-primary">Sign In <i *ngIf="loadFlag2"
                class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
          </div>
          <div class="form-group text-center">
            <span class="text-muted">Don't have an account yet? <a [routerLink]="['/signup']">Sign Up</a></span>
          </div>
          <div class="form-group text-center">
            <span class="text-muted">Do you want to donate? <a [routerLink]="['/donation']">Donate Now</a></span>
          </div>
          <div class="form-group text-center">
            <span class="text-muted">Interested in fundraising? <a [routerLink]="['/fundraisers/home']">Explore
                Fundraiser</a></span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <p class="d-none">{{'Application version: ' + version_info.version + ', git-hash: ' + version_info.hash}}</p>
</div>