import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAmountValidator]',
  providers: [],
})
export class AmountValidatorDirective {
  @Input() maxLength: string;
  public previousValue: any;

  constructor(public ele: ElementRef) {}

  @HostListener('keydown', ['$event'])
  keyDown(event: KeyboardEvent): any {
    if (this.ele.nativeElement.max) {
      this.maxValueValidation(event);
    }
    if (this.keyValidation(event)) {
      console.log('preventDefault', this.keyValidation(event));
      event.preventDefault();
      return false;
    }
  }

  @HostListener('keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    // this is for restrict more dots in mozila firefox
    if (this.ele.nativeElement.value == '') {
      this.ele.nativeElement.valueAsNumber = Number(this.previousValue);
    }
    if (
      this.ele.nativeElement.id === 'amount' ||
      this.ele.nativeElement.id === 'hst' ||
      this.ele.nativeElement.id === 'donation_amount' ||
      this.ele.nativeElement.id === 'event_cost' ||
      this.ele.nativeElement.id === 'cost' ||
      this.ele.nativeElement.id === 'course_fee' ||
      this.ele.nativeElement.id === 'salary' ||
      this.ele.nativeElement.id === 'value'
    ) {
      if (
        this.ele.nativeElement.max &&
        this.ele.nativeElement.value.indexOf('.') < 0
      ) {
        if (
          Number(this.ele.nativeElement.value) >
          Number(this.ele.nativeElement.max)
        ) {
          this.ele.nativeElement.valueAsNumber = Number(
            this.ele.nativeElement.value,
          );
        }
      } else {
        let amount = this.ele.nativeElement.value.split('.');
        let decimalAmount =
          amount[1].length > 2
            ? amount[1].toString().substring(0, 2)
            : amount[1];
        decimalAmount = amount[0] + '.' + decimalAmount;
        this.ele.nativeElement.value = decimalAmount;
      }
    } else {
      if (this.ele.nativeElement.max) {
        if (
          Number(this.ele.nativeElement.value) >
          Number(this.ele.nativeElement.max)
        ) {
          this.ele.nativeElement.value = this.ele.nativeElement.value
            .toString()
            .substring(0, this.ele.nativeElement.value.length - 1);
          this.ele.nativeElement.valueAsNumber = Number(
            this.ele.nativeElement.value,
          );
        }
      }
    }
  }

  keyValidation(event: any) {
    return event.key === 'e' || event.key === '-';
  }

  maxValueValidation(event: any) {
    if (
      Number(this.ele.nativeElement.value) >=
        Number(this.ele.nativeElement.max) &&
      /^[0-9]+$/.test(event.key)
    ) {
      this.ele.nativeElement.value = this.ele.nativeElement.value
        .toString()
        .substring(0, this.ele.nativeElement.value.length - 1);
      this.ele.nativeElement.valueAsNumber = Number(
        this.ele.nativeElement.value,
      );
    }
  }

  maxLengthValidation(event: any) {
    if (this.maxLength) {
      return (
        this.ele.nativeElement.value.length >= this.maxLength &&
        /^[0-9]+$/.test(event.key)
      );
    } else {
      return false;
    }
  }
}
