import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.css'],
})
export class PaymentSuccessPageComponent
  extends BaseComponent
  implements OnInit
{
  public paymentUrl: string = AppConstants.BASE_URL + '/payment/';

  urlParam: any;
  paymentStatus: string = 'received';
  transactionStatus: string = 'Successful';
  accountNumber: string;
  response: any;
  recurring: any;
  imageSrc: string =
    'http://dgtlmrktng.s3.amazonaws.com/go/emails/generic-email-template/tick.png';
  showPrint: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    service: DataManagerService,
    private fb: FormBuilder,
  ) {
    super();
    this.service = service;
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.urlParam = params;
      }
    });
    this.paymentUrl = this.paymentUrl + this.urlParam['id'];
  }

  ngOnInit(): void {
    this.service.getById(this.paymentUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.data = res.data;
          this.response = JSON.parse(this.data.response_received);
          this.recurring = this.data.recurring;
          if (this.response.trnApproved != 1) {
            this.paymentStatus = 'Denied';
            this.transactionStatus = 'Denied';
            this.imageSrc = 'assets/delete.png';
          }

          this.accountNumber =
            this.response?.cardBin + '********' + this.response?.cardLastFour;
        }
      },
      error: (error) => {
        this.router.navigate(['/login']);
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  getRecurringType(recurring_count: any) {
    switch (recurring_count) {
      case 2:
        return 'Daily';
      case 3:
        return 'Weekly';
      case 4:
        return 'Monthly';
      case 5:
        return 'Yearly';
      default:
        return '';
    }
  }
  printPage(event: any) {
    this.showPrint = false;
    window.print();
    this.showPrint = true;
  }
}
