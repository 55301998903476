import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-success-fund-message',
  templateUrl: './success-fund-message.component.html',
  styleUrls: ['./success-fund-message.component.css'],
})
export class SuccessFundMessageComponent implements OnInit {
  urlParam: any;
  fundraiser: any;
  data: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
  ) {
    this.route.params.subscribe((params) => {
      if (params) {
        this.urlParam = params;
      }
    });
  }
  calculateProgressBarWidth(fundraiser: any): string {
    const percentage =
      (fundraiser.current_amount / fundraiser.target_amount) * 100;
    if (percentage == 0) return '1' + '%';
    return percentage + '%';
  }
  ngOnInit(): void {
    this.getFundraisers();
  }

  getFundraisers() {
    if (this.urlParam) {
      this.httpClient
        .get(AppConstants.BASE_URL + '/fund/' + this.urlParam['fund_id'])
        .subscribe(
          (data: any) => {
            if (data.status == 'failed') {
              this.router.navigate(['/fundraisers/home']);
            }
            this.fundraiser = data.data.fundraiser;
            this.data = data.data;
          },
          (error) => {
            console.error('Error fetching fundraisers:', error);
          },
        );
    }
  }
}
