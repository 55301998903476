import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { AppConstants } from '../shared/constants/app-constants';
import { BaseListComponent } from '../shared/abstract/base-list.component';
import { HeaderConstants } from '../shared/constants/header-constants';
import { PermissionConstants } from '../shared/constants/permission-constants';
import { EventComponent } from '../events/event/event.component';
import { ApprovalDialogComponent } from '../dialogs/approval-dialog/approval-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FuneralPaymentDialogComponent } from 'src/app/dialogs/funeral-payment-dialog/funeral-payment-dialog.component';
import { DatePipe } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { ImageShowerDialogComponent } from 'src/app/dialogs/image-shower-dialog/image-shower-dialog.component';
import { Subject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-view-event-particpants',
  templateUrl: './view-event-particpants.component.html',
  styleUrls: ['./view-event-particpants.component.css'],
})
export class ViewEventParticpantsComponent
  extends BaseListComponent
  implements OnInit, OnDestroy
{
  public view_event: any;
  public viewData: Object;
  public viewparticipant = AppConstants.BASE_URL + '/event_participant';
  public event_url: string = AppConstants.BASE_URL + AppConstants.ADDEVENT_URL;
  event_id: any;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  showExport: boolean = true;
  public output: any;
  isModalOpen: boolean = false;

  public qrCodeResult: any;

  serial: any;
  constructor(
    dataManagerService: DataManagerService,
    private event: EventComponent,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    super();
    this.page = 1;
    this.rows = 10;
    this.service = dataManagerService;
    this.searchHits = new Subject<void>();

    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.event_id = params['id'];
      }
    });
    this.getFormData();
    this.getTableSettings();

    this.getListUrl =
      AppConstants.BASE_URL +
      AppConstants.EVENT_PARTICIPANTS_URL +
      '/' +
      this.event_id;
    this.columnDefinition = HeaderConstants.eventParticipants;
    this.event.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_ADD,
    );
    this.event.delete_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_REMOVE,
    );
    this.event.view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_VIEW,
    );
    this.event.edit_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_EDIT,
    );
    this.event.excel_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_EXCEL,
    );
    this.permissionsCheck = {
      edit_permission: this.event.edit_permission,
      delete_permission: this.event.delete_permission,
      view_permission: this.event.view_permission,
      create_permission: this.event.create_permission,
      excel_permission: this.event.excel_permission,
    };
  }

  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
  protected getListRes(res: any): void {
    this.rowData = [];
    this.loader = true;

    this.resultsLength = res.data.pagination.total;
    res.data.rowData.forEach((element: any, index: number) => {
      element.event_date = element.event.event_date;
      element.deleteForOnlySuperadmin_Truserer = true;
      element.user_id = this.loged_user.gid;
      for (let i in element) {
        if (i === 'gender') {
          element[i] =
            element[i] === 1 ? 'Male' : element[i] === 2 ? 'Female' : 'N/A';
        }
        if (i == 'amount_after_discount') {
          element[i] = Number(element[i]).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        }
        if (i === 'contact_number') {
          if (!this.isNullOrUndefined(element[i])) {
            const phone = element[i].match(/(\d{3})(\d{3})(\d{4})/);
            element[i] =
              '+1 ' + '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
          }
        }
        if (i === 'payment_sts') {
          element.eventPayment_sts =
            element[i] == null && element.payment_method != null
              ? 'Pending'
              : element[i] == 1
                ? 'Completed'
                : element[i] == -1
                  ? 'Verify Payment'
                  : element[i] == null && element.payment_method == null
                    ? 'N/A'
                    : '';
          // element.eventPayment_sts = (element[i] == 0 || element[i] == null) ? 'Pending' : element[i] === -1 ? 'Verify Payment':  'Completed'
        }
        if (i == 'event_date') {
          element[i] = this.datePipe.transform(element[i], 'yyyy-MM-dd');
        }
        if (i == 'payment_method') {
          element['mode_payment'] =
            element[i] == 1
              ? 'Cash'
              : element[i] == 4
                ? 'Online'
                : element[i] == 5
                  ? 'POS'
                  : 'N/A';
        }
        if (i == 'payment_method' && element[i] != null) {
          element[i] =
            element[i] == 1 ? 'Cash' : element[i] == 4 ? 'Online' : 'POS';
        }
      }
      this.rowData.push(element);
    });
    this.loader = false;
  }
  protected getListErr(err: any): void {
    console.log('err', err);
  }
  ngOnDestroy(): void {
    console.log('ngOnDestroy');
  }
  getActions(data: any) {
    if (data.actions === 'payment') {
      const paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDEVENT_URL +
        '/eventPayment/' +
        data.data.id;
      const dialogRef = this.dialog.open(FuneralPaymentDialogComponent, {
        data: {
          Header: 'Event Register Payment',
          url: paymentUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (!confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname], {
                queryParams: { id: this.event_id },
                relativeTo: this.route,
              });
            });
        }
      });
    } else if (data.actions === 'downloadPDF') {
      const downloadPDFUrl =
        AppConstants.BASE_URL +
        '/payment/downloadpdf/' +
        data.data.transcation_id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Do you want to download this Event Receipt?',
          url: downloadPDFUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname], {
                queryParams: { id: this.event_id },
                relativeTo: this.route,
              });
            });
        }
      });
    } else if (data.actions === 'Verify Payment') {
      const verifyPaymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDEVENT_URL +
        '/eventPayment/' +
        data.data.id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Payment is done through Cash!',
          data: data,
          url: verifyPaymentUrl,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname], {
                queryParams: { id: this.event_id },
                relativeTo: this.route,
              });
            });
        }
      });
    } else if (data.actions === 'delete') {
      const deleteEventParticipantUrl =
        AppConstants.BASE_URL + '/event_register/delete';
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this Event Participants?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.id],
          };
          this.service.delete(deleteEventParticipantUrl, payload).subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([location.pathname], {
                      queryParams: { id: this.event_id },
                      relativeTo: this.route,
                    });
                  });
              } else {
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
        }
      });
    }
  }
  getFormData() {
    this.formLoader = true;
    this.service.getById(this.event_url + '/' + this.event_id).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          if (res.data) {
            this.data = res.data.row;
            const phone = this.data.contact_number.match(
              /(\d{3})(\d{3})(\d{4})/,
            );
            this.data.contact_number =
              '+1 ' + '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
            this.data.event_cost = Number(this.data.event_cost).toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
              },
            );
          }
          this.formLoader = false;
        } else {
          this.formLoader = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  onTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel == 'Event Participants') {
      this.showExport = true;
    } else {
      this.showExport = false;
    }
  }
  exportexcel() {
    this.loadFlag2 = true;
    const exportUrl =
      AppConstants.BASE_URL + '/eventParticipant/export/' + this.event_id;
    this.service.exportToExcel(exportUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 200) {
          this.loadFlag2 = false;
          this.downloadExcel(res);
          this.service.showSnackBar('File is loading', 'ok', 3000);
        } else {
          this.loadFlag2 = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loadFlag2 = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  imageView() {
    let myImg: any = document.querySelector('#imgId');
    var realWidth = myImg.naturalWidth;
    var realHeight = myImg.naturalHeight;
    console.log('realHeight', realHeight, 'realWidth', realWidth);
    const dialogRef = this.dialog.open(ImageShowerDialogComponent, {
      data: {
        url: this.data.event_cover_img,
        width: realWidth,
        hight: realHeight,
        buttonText: {
          cancel: 'Cancel',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
      }
    });
  }

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo | null = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string | null;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.searchText = resultString;
    this.setUrl();
    this.closeModal();
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
