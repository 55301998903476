import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { MaterialModule } from './material.module';
import { ToggleColumnsPipe } from './constants/toggle-columns.pipe';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PhoneMaskDirective } from './Validators/phone-mask/phone-mask.directive';
import { WeeksSchoolComponent } from './components/weeks-school/weeks-school.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AmountValidatorDirective } from './Validators/amount-validator/amount-validator.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    NgxSpinnerModule,
  ],
  declarations: [
    GenericTableComponent,
    ToggleColumnsPipe,
    PhoneMaskDirective,
    WeeksSchoolComponent,
    CheckoutComponent,
    AmountValidatorDirective,
  ],
  exports: [
    GenericTableComponent,
    ToggleColumnsPipe,
    MaterialModule,
    PhoneMaskDirective,
    WeeksSchoolComponent,
    CheckoutComponent,
    AmountValidatorDirective,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DataManagerService],
    };
  }
}
