<div class="component-container">
    <app-fund-navbar></app-fund-navbar>


    <div class="container">
        <div class="current-fundraisers row" *ngIf="fundraiser">

            <div class="fundraiser-card">
                <button class="btn btn-primary" style="max-width:90px;" [routerLink]="['/fundraisers/home']"
                    name="back"><i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                <img class="fundraiser-image" [src]="fundraiser?.cover_image" alt="{{ fundraiser?.title }}" />
                <div>
                    <h2>{{ fundraiser?.title }}</h2>
                    <div class="fundraiser-description">
                        <p>{{ fundraiser?.description }}</p>
                    </div>
                    <div style="margin-bottom:-20px">
                        <h4 style="font-weight:bolder">+ You Have raised it by <span style="color:green">${{
                                data.fund_amount }}</span></h4>
                    </div>
                    <div class="progress-bar">
                        <div class="fill" [style.width]="calculateProgressBarWidth(fundraiser)"></div>
                    </div>
                    <div class="fundraiser-amounts">
                        <span style="font-weight:500"> ${{ fundraiser.current_amount }} raised of</span> ${{
                        fundraiser?.target_amount }}
                    </div>
                </div>

                <div *ngIf="fundraiser.status=='active'">
                    <div class="alert alert-success mt-2" role="alert">
                        🌟 Thanks for your support! The fundraiser is still in progress, and your contributions are
                        making a difference. Together, we can reach our goal!
                    </div>
                </div>
                <div *ngIf="fundraiser.status=='completed'">
                    <div class="alert alert-success mt-2" role="alert">
                        🎉 Thank you! The fundraiser has successfully reached its goal. Your contribution made a
                        significant impact. Stay tuned for more opportunities to make a difference!

                    </div>
                </div>
            </div>
        </div>