import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../../services/DataManager/data-manager.service';
import { BaseComponent } from '../../shared/abstract/base.component';
import { AppConstants } from '../../shared/constants/app-constants';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
  @Input() public toggleSideNav: boolean;
  public navUrl: string = window.location.pathname;
  public looutUrl: string = AppConstants.BASE_URL + '/user/logout';
  showFiller = false;
  @ViewChild('drawer') snav: MatSidenav;
  mobileQuery: MediaQueryList;
  isExpanded: boolean = true;
  public logoutUrl: string = AppConstants.BASE_URL + AppConstants.LOGOUT_URL;
  menuList: any;
  menuOpen: boolean = false;
  public sidemenuUpdated: number = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private route: ActivatedRoute,
    private DataManagerService: DataManagerService,
    private router: Router,
    private breakPoint: BreakpointObserver,
  ) {
    super();
    this.getLoginStatus();

    this.mobileQuery = this.media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.DataManagerService.status.subscribe((value) => {
      if (value) {
        if (this.sidemenuUpdated == 1) {
          this.menuList = this.DataManagerService.menuList;
          this.updateSideMenu();
        } else {
          this.sidemenuUpdated = 2;
        }
        this.toggleSideNav = true;
      } else {
        this.sidemenuUpdated = 1;
        this.toggleSideNav = false;
      }
    });
  }

  ngOnInit() {
    this.updateSideMenu();
    this.DataManagerService.LoginStatus();
  }
  private _mobileQueryListener: () => void;
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
  getLoginStatus() {
    let status = this.DataManagerService.LoginStatus();
    if (status) {
      let route = window.location.pathname;
      // this.router.navigate([route])
    }
  }

  /**
   * This method is use to open side nav on mobile view
   */
  openSideNav() {
    if (this.mobileQuery.matches) {
      this.isExpanded = true;
      this.snav.opened = true;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
    this.DataManagerService.logout(this.looutUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          localStorage.clear();
          localStorage.removeItem('authToken');
          localStorage.removeItem('user');
          localStorage.removeItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS);
          this.DataManagerService.showSnackBar(res.message, 'ok', 3000);
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/login']);
            });
        } else {
          this.DataManagerService.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.DataManagerService.showSnackBar(error.message, 'ok', 3000);
      },
    });
    this.DataManagerService.loggedInStatus = false;
  }

  updateSideMenu() {
    this.sidemenuUpdated += 1;
    this.menuList = localStorage.getItem('user');
    this.menuList = JSON.parse(this.menuList);
  }
  expandSubmenu(sub: any, routes: string) {
    this.router.navigate([sub.routes + routes]);
  }
  toggle() {
    const isSmallScreen = this.breakPoint.isMatched('(max-width: 767px)');
    if (isSmallScreen) {
      this.snav.toggle();
    }
    this.snav.opened = false;
  }
}
