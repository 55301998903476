<div class="">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col pr-0">
                <h2 class="title">{{headings}}</h2>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="nsicc-modal-content">
        <div class="form-ajax-box">
            <form class="form-horizontal nsicc-form validated" [formGroup]="expenseCategoryForm"
                (ngSubmit)="onSubmit()">
                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                    <div class="row">
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <label for="cat_name">Category Name <span class="asterix">
                                    * </span></label>
                            <input type="text" name="cat_name" id="cat_name" formControlName="cat_name"
                                class="form-control" (keypress)="letterOnly($event)" maxlength="250"
                                [ngClass]="{ 'is-invalid': submitted && expenseCategoryFormControl.cat_name.errors }">
                            <div *ngIf="submitted && expenseCategoryFormControl.cat_name.errors"
                                class="invalid-feedback">
                                <div *ngIf="expenseCategoryFormControl.cat_name.errors.required">Category name is
                                    required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <mat-form-field appearance='outline'>
                                <mat-label>Select a Category Type</mat-label>
                                <mat-select name="category_type_id" formControlName="category_type_id"
                                    [ngClass]="{ 'is-invalid': submitted && expenseCategoryForm.value.category_type_id.errors }">
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let type of types" [value]=" type.id ">
                                        {{ type.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" style="align-items:baseline;">
                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex mt-3">
                            <div class="btn-group mr-2">
                                <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i
                                        *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>