<div class="login-logo"> </div>

<div class="container">

    <div class="card">
        <mat-toolbar class="main-header">
            <mat-toolbar-row>
                <span>Unsubscribe payment</span>
                <span class="example-spacer"></span>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body">
            <div class="form-ajax-box">
                <div class="letter-img">
                    <img src="./assets/cancel.svg" class="letter-img">
                </div>
                <div class="bottom text-left">
                    <h2 class="title"> We're sorry to hear that you have canceled your payment with us. </h2>
                    <p class="subtitle">

                        We value your business and are genuinely disappointed that you have decided to stop
                        using our services. If there's anything we can do to address your concerns or improve
                        your experience, please don't hesitate to reach out to us. We appreciate the opportunity
                        to serve you and hope to have the chance to regain your trust in the future.</p>

                    <div class="buttons">

                        <button id="cancel" (click)="backtoHome()" class="btn btn-warning">Back to Home</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<br>
<div class="login-logo"> </div>