import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManagerService } from '../../services/DataManager/data-manager.service';
import { BaseComponent } from '../../shared/abstract/base.component';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.css'],
})
export class ApprovalDialogComponent extends BaseComponent implements OnInit {
  message: string = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<ApprovalDialogComponent>,
    service: DataManagerService,
  ) {
    super();
    this.service = service;
    if (this.dialogData) {
      console.log('this.dialogData', this.dialogData);
      this.message = dialogData.message || this.message;
      if (dialogData.buttonText) {
        this.confirmButtonText =
          dialogData.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText =
          dialogData.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.loadFlag2 = true;
    if (
      this.dialogData.data.actions == 'reject' ||
      this.dialogData.data.actions == 'approve'
    ) {
      this.service.approveOrReject(this.dialogData.url).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogData.data.actions == 'Verify Payment') {
      let payload: any;
      payload = {
        payment_method:
          this.dialogData.data.data.payment_method == 'Cash'
            ? 1
            : this.dialogData.data.data.payment_method == 'Cheque'
              ? 2
              : this.dialogData.data.data.payment_method == 'E-Transfer'
                ? 3
                : this.dialogData.data.data.payment_method == 'Online'
                  ? 4
                  : 5,
      };
      this.dialogData.data.data.school_payment
        ? (payload['school_id'] =
            this.dialogData.data.data.school_payment[0]?.sc_reg_id)
        : console.log('no school id');
      this.service.create(this.dialogData.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogData.data.actions == 'downloadPDF') {
      this.service.exportToPDF(this.dialogData.url).subscribe({
        next: (res: any) => {
          if (res) {
            this.blob = new Blob([res], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(res);
            var a = document.createElement('a');
            a.href = downloadURL;
            a.setAttribute('target', '_blank');
            a.click();
            this.dialogRef.close(true);
          } else {
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogData.data.actions == 'payment') {
      let payload = {
        payment_method:
          this.dialogData.data.data.payment_method == 'Cash'
            ? 1
            : this.dialogData.data.data.payment_method == 'Cheque'
              ? 2
              : this.dialogData.data.data.payment_method == 'E-Transfer'
                ? 3
                : this.dialogData.data.data.payment_method == 'Online'
                  ? 4
                  : 5,
      };
      this.service.create(this.dialogData.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success' && res.data) {
            this.dialogRef.close(res.data);
          } else if (res['status'] == 'success' && !res.data) {
            this.dialogRef.close(false);
            this.service.showSnackBar(res.message, 'ok', 3000);
          } else {
            this.dialogRef.close(false);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.dialogRef.close(false);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogData.data.actions == 'active') {
      this.service.approveOrReject(this.dialogData.url).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogData.data.actions == 'Unsubscribe') {
      const payload: FormData = new FormData();
      payload.append('rbAccountId', this.dialogData.data.data.account_id);
      payload.append('operation', '3');
      this.service.cancelDonation(this.dialogData.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else {
      this.dialogRef.close(false);
    }
  }
  ngOnInit(): void {
    console.log('ngOnInit:this.dialogData', this.dialogData);
  }
}
