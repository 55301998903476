<div
    class="dropdown col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-lg-flex d-sm-flex d-md-flex d-xl-flex d-inline justify-content-end p-xl-0 p-md-0 p-sm-0 p-0">

</div>
<mat-card appearance="outlined" class="p-3">
    <section class="description">
        <article>
            <h2 class="text-center"> Darul'Ilm Academy Payment Reports</h2>
            <h3>Description</h3>
            <p> This page allows you to effortlessly export payment data for different school sessions.
                Whether it's weekend or Al-Furqan school payments
                you can easily generate detailed reports to keep track of financial transactions.</p>
        </article>

        <article>
            <h3>How to Use</h3>
            <p> Select the date range for the payments you want to export,
                and then choose the export option that best fits your needs.
                Generate organized reports to help streamline your financial management.</p>
        </article>
        <div class="mb-3 d-flex justify-content">
            <mat-form-field class="full-width" appearance="outline"
                class="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-6 p-0" (click)="picker.open()">
                <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="reportFromDate" required
                    placeholder="From date" (dateChange)="dateChange('fromDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline"
                class="col-md-6 col-lg-6 col-xl-6 col-sm-6 col-6 p-lg-0 p-0 p-sm-0 mx-sm-3 mx-lg-3 ml-3">
                <input matInput [matDatepicker]="picker1" (click)="picker1.open()" required placeholder="To date"
                    [min]="reportFromDate" [max]="reporttoDate" (dateChange)="dateChange('toDate', $event)"
                    [(ngModel)]="reporttoDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <article class="mb-3">
            <h3>Export Weekend School Payment Data</h3>
            <p>
                Easily export detailed payment information for weekend school sessions.
                This feature helps you keep track of payments made during weekends.
            </p>
            <button mat-raised-button class="btn-block buttonColor" (click)="exportPaymentData(1)">Export</button>
        </article>
        <article class="mb-3">
            <h3>Export Al-Furqan Program Payment Data</h3>
            <p>
                Efficiently export payment records related to Al-Furqan Program sessions.
                Stay organized and manage your Al-Furqan Program payment data effortlessly.
            </p>
            <button mat-raised-button class="btn-block buttonColor mb-2 " (click)="exportPaymentData(2)">Export</button>
        </article>
        <article>
            <h3>Export Purchased Courses Payment Data</h3>
            <p>
                Effortlessly manage payment records for purchased courses by efficiently exporting data. Stay organized
                with a streamlined process for handling your course payment information.
            </p>
            <button mat-raised-button class="btn-block buttonColor mb-2 " (click)="exportPaymentData(3)">Export</button>
        </article>
    </section>
</mat-card>