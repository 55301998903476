<div class="component-container">

    <!-- Navbar -->
    <app-fund-navbar></app-fund-navbar>

    <!-- Hero Section -->
    <div class="container">
        <div class="hero-section">
            <div class="row hero-row">
                <div class="hero-text col-xl-5 col-lg-5 col-md-7 col-sm-12 col-xs-12">
                    <div class="row space-up">
                        <h1>Fundraising for the people and charities you care about</h1>
                    </div>
                    <div class="row 
                hero-paragraph">
                        <p>Get help. Give kindness. Start in just 5 minutes.</p>
                    </div>
                    <div class="row">
                        <button (click)="scrollToSection('section2')" class=" btn btn-primary browse-buttton">Browse
                            Fundraisers</button>
                    </div>
                </div>
                <div class=" hero-image col-xl-7 col-lg-7 col-md-5 col-sm-12 col-xs-12">
                    <img class="landing-image" src="../../assets/landing.png">
                </div>
            </div>
        </div>
    </div>
    <div id="section2"> <br> <br> <br> <br></div>
    <!-- Current Fundraisers Section -->
    <div class="fundraisers-section mb-2 mt-2">
        <div class="mb-2">
            <span class="bubble-element Text"> Current fundraisers</span>

            <input class="search-input" type="text" [(ngModel)]="searchQuery" placeholder="Search..." />
            <button class="search-button" (click)="onSearch()">Search</button>

        </div>

        <ng-container *ngIf="loading">
            <div class="text-center loader" *ngIf="loading">
                <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                </mat-progress-spinner>
            </div>
        </ng-container>
        <div class="current-fundraisers row" *ngIf="!loading">
            <div class=" col-md-5 col-xl-3 col-lg-4 col-sm-6 col-xs-12" *ngFor="let fundraiser of fundraisers">
                <div class="fundraiser-card" (click)="navigateToFundraiserDetails(fundraiser.slug)">
                    <img class="fundraiser-image" [src]="fundraiser.cover_image" alt="{{ fundraiser.title }}" />
                    <div>
                        <h2 class="fund-title">{{ fundraiser.title }}</h2>
                        <!-- <span class="badge text-bg-primary mb-2" *ngIf="fundraiser.status=='active'">Active</span> -->

                        <span class="badge text-bg-success mb-2" *ngIf="fundraiser.status=='completed'">Completed</span>
                        <div class="fundraiser-description mt-2" (click)="$event.stopPropagation()"
                            [ngStyle]="{'max-height': isReadMore ? 'none' : '60px'}">
                            <p class="fund-description">{{ fundraiser.description }}</p>
                        </div>
                        <div *ngIf="fundraiser.description.length > 60" class="read-more-button"
                            (click)="toggleReadMore($event);$event.stopPropagation()">
                            {{ isReadMore ? 'Read Less' : 'Read More' }}
                        </div>
                        <div class="progress-bar">
                            <div class="fill" [style.width]="calculateProgressBarWidth(fundraiser)"></div>
                        </div>
                        <div class="fundraiser-amounts">
                            <span style="font-weight:500"> ${{ fundraiser.current_amount }} raised of</span> ${{
                            fundraiser.target_amount }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3 mt-4" *ngIf="fundraisers.length==0">
            <div class="message-container">
                <div class="message">
                    <h2>No Ongoing Fundraising Campaigns Right Now</h2>
                    <p>Oops! It seems like there are no active fundraisers at the moment.</p>
                    <p>You can make a difference! Stay tuned for upcoming opportunities to support meaningful causes.
                    </p>
                </div>
            </div>
        </div>
        <div class="pagination" *ngIf="fundraisers.length !==0">
            <app-pagination [currentPage]="currentPage" [pageSize]="pageSize" [totalItems]="totalItems"
                [pageSizes]="[4, 16, 32, 64]" (pageChange)="onPageChange($event)"
                (pageSizeChange)="onPageSizeChange($event)">
            </app-pagination>
        </div>
    </div>