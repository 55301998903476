import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-view-donation',
  templateUrl: './view-donation.component.html',
  styleUrls: ['./view-donation.component.css'],
})
export class ViewDonationComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  donation_url: any;

  constructor(
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
  ) {
    super();
    this.service = dataManagerService;

    this.donation_url = AppConstants.BASE_URL + AppConstants.ADDDONATIONS_URL;
    this.countryListUrl = AppConstants.BASE_URL + '/country';
    this.getCountryList();
    this.maritalStatusUrl = AppConstants.BASE_URL + '/maritalStatus';
    this.stateListUrl = AppConstants.BASE_URL + '/getState';
    this.cityListUrl = AppConstants.BASE_URL + '/getCity';
    this.getDonationType();
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    console.log('ngOnInit');
  }

  getDonationType() {
    this.formLoader = true;
    const getListUrl = AppConstants.BASE_URL + '/donation_type';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.dontationTypeList = res.data;
          if (this.dontationTypeList) {
            this.getFormData();
          }
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }

  getFormData() {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.formLoader = true;
        this.service.getById(this.donation_url + '/' + params['id']).subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.data = res.data.row;
              if (res.data.row.contact_number) {
                const phone = res.data.row.contact_number.match(
                  /(\d{3})(\d{3})(\d{4})/,
                );
                res.data.row.contact_number =
                  '+1 ' + '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
              }
              res.data.row.donation_amount = Number(
                res.data.row.donation_amount,
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              this.dontationTypeList.forEach((donationType: any) => {
                if (
                  donationType.donation_type_id == res.data.row.donation_type
                ) {
                  res.data.row.donation_type = donationType.type_name;
                }
              });
              this.selectdCountry = res.data.row.country;
              this.changeCountry();
              this.selectdState = res.data.row.state;
            } else {
              this.formLoader = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.formLoader = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
      } else {
      }
    });
  }
}
