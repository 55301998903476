<div id="checkoutView" class="mx-3">
    <div class="card ">
        <div class="row">
            <div class="col py-4">
                <h1 class="main-title">Checkout</h1>
            </div>
        </div>
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3" *ngIf="!formLoader; else showLoader">
            <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
                <div class="">
                    <div class="row">
                        <div class="col-lg-6 col-xl-6 col-md-6 col-12">
                            <div class="row">
                                <div class="col-12 p-0 mt-4">
                                    <h1 class="title">Customer Details</h1>
                                    <div class="row">
                                        <label class="form-label col-12 p-0">Name: {{data.user_name}}</label>
                                    </div>
                                    <div class="row">
                                        <label class="form-label col-12 p-0">Email: {{data.email}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 p-0 mt-4">
                                    <h1 class="title">Delivery Details</h1>
                                    <div class="row">
                                        <div class="col-12 d-flex align-items-center pl-0">
                                            <label class="form-label mr-3 mb-0">Online</label>
                                            <input type="checkbox" id="online" name="online" value="3" checked disabled>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 p-0 mt-4">
                                    <h1 class="title">Order Details</h1>
                                    <div class="row">
                                        <div class="col-12 d-flex align-items-center pl-0 pr-0">
                                            <label class="form-label col-md-8 col-xl-6 col-lg-6 col-sm-9 col-9 p-0">Payment for {{data.payable_type}} at
                                                NSICC</label>
                                            <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-sm-3 col-3 ">{{ isNullOrUndefined( data.amount_after_discount) ? data.amount : data.amount_after_discount}}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 col-md-6 col-12">
                            <div class="row">
                                <div class="col-12 p-0 mt-4">
                                    <h1 class="title">Payment Details</h1>
                                    <div class="row">
                                        <label class="form-label col-md-8 col-xl-6 col-lg-6 col-sm-9 col-9  pl-0">Price (in CAD)</label>
                                        <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-3 col-sm-3">{{data.amount}}</label>
                                    </div>
                                        <div class="row">
                                        <label class="form-label col-md-8 col-xl-6 col-lg-6 col-sm-9 col-9  pl-0">Discount (in CAD)</label>
                                        <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-3 col-sm-3">{{ data.discount_amount }}</label>
                                    </div>
                                    <div class="row">
                                        <label class="form-label col-md-8 col-xl-6 col-lg-6 col-sm-9 col-9 p-0">Tax Amount (in CAD)</label>
                                        <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-3 col-sm-3">$0.00</label>
                                    </div>
                                    <div class="row">
                                        <label class="form-label col-md-8 col-xl-6 col-lg-6 pl-0 col-sm-9 col-9">Delivery / Shipping charges (in
                                            CAD)</label>
                                        <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-3 col-sm-3">$0.00</label>
                                    </div>
                                    <div class="row">
                                        <label class="form-label col-md-8 col-xl-6 col-lg-6 pl-0 col-sm-9 col-9"><strong>Payable (in CAD)</strong> </label>
                                        <label class="form-label col-md-4 col-xl-6 col-lg-6 pr-0 col-3 col-sm-3"><strong>{{
                                            isNullOrUndefined( data.amount_after_discount) ? data.amount : data.amount_after_discount}}</strong></label>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="control col-12 mt-4">
                        <label class="checkbox mb-0 d-flex-inline d-lg-flex d-sm-flex d-md-flex d-xl-flex mr-2">
                            
                            <input type="checkbox" formControlName="terms_condition" class="mr-2"
                                [ngClass]="{ 'is-invalid': submitted && checkoutFormControl.terms_condition.errors }">
                                <span class="d-flex-inline d-lg-flex d-sm-flex d-md-flex d-xl-flex">I have read and agreed to<a href="https://nsicc.ca/privacy-policy/" target="_blank" class=" ml-2 mr-2">Privacy Policy </a>|<a href="https://nsicc.ca/refund-policy/" target="_blank" class="ml-2">Refund Policy </a></span>
                        </label>
                        <div *ngIf="submitted && checkoutFormControl.terms_condition.errors"
                            class="invalid-feedback">
                            <div *ngIf="checkoutFormControl.terms_condition.errors.required">Please accept the Terms and Policy.
                            </div>
                        </div>
                    </div>
                    <div class="row pl-3" style="align-items:baseline;">
                        <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex pl-0 mt-2">
                            <div class="btn-group mr-2">
                                <button class="btn btn-primary" name="save" [disabled]="loadFlag2">{{buttonText}} <i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
        <ng-template #showLoader>
            <mat-card appearance="outlined" class="text-center loader">
                <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                    color="accent">
                </mat-progress-spinner>
            </mat-card>
        </ng-template>
    </div>
</div>