import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-view-discount-dialog',
  templateUrl: './view-discount-dialog.component.html',
  styleUrls: ['./view-discount-dialog.component.css'],
  encapsulation: ViewEncapsulation.None, // Use None to disable encapsulation
})
export class ViewDiscountDialogComponent implements OnInit {
  public data: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ViewDiscountDialogComponent>,
  ) {
    if (this.dialogData) {
      this.data = this.dialogData.data;
      console.log('this.dialogData', this.dialogData);
    }
  }

  ngOnInit(): void {
    console.log('ngOnInit');
  }
  close() {
    this.dialogRef.close(true);
  }
}
