import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy/MM/dd',
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'yyyy/MM/dd',
    dateA11yLabel: 'yyyy/MM/dd',
    monthYearA11yLabel: 'yyyy/MM/dd',
  },
};

@Component({
  selector: 'app-course-register-dialog',
  templateUrl: './course-register-dialog.component.html',
  styleUrls: ['./course-register-dialog.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class CourseRegisterDialogComponent
  extends BaseComponent
  implements OnInit
{
  public RegisterCourseForm: FormGroup;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public userLevelList: any;
  public showtoAdmin: boolean;
  public showresponse: boolean = false;
  public courseFeewithDiscount: any;
  public discount_id: any;
  public minDate: Date;
  public totalPerMonth: number;
  public numberOfMonths: number;
  public maxDate: Date;
  course_fee: number;
  token: any;
  public isLoading: boolean = false;
  formMessage: string;
  duration: string = 'Per Month';
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<CourseRegisterDialogComponent>,
  ) {
    super();
    this.service = dataManagerService;
    if (dialogData) {
      this.data = JSON.parse(JSON.stringify(this.dialogData));
      let durationString = this.data.data.course_duration.replace(
        /\s*days\s*/i,
        '',
      );
      const days = Number(durationString);
      console.log('days', days);
      console.log('this.data', this.data);
      console.log('this.dialogData', this.dialogData);
      // Split the string into parts

      this.headings = this.dialogData.Header;
      this.courseFeewithDiscount = Number(this.data.data.couseFeeTotal);
      let courseFeeString = this.data.data.couseFeeTotal;

      // // Check if the string contains a dollar sign at the beginning
      // if (courseFeeString.startsWith('$')) {
      //   // Remove the dollar sign at the beginning
      //   courseFeeString = courseFeeString.substring(1);
      // } else if (courseFeeString.endsWith('$')) {
      //   // Remove the dollar sign at the end
      //   courseFeeString = courseFeeString.slice(0, -1);
      // }

      // Convert the extracted string to a number
      this.course_fee = Number(courseFeeString);
      this.numberOfMonths = this.data.data.paymentsMonths;
      this.totalPerMonth = this.data.data.courseFeePerMonth;

      this.buttonText = 'Proceed';
      const currentYear = new Date().getFullYear();
      this.minDate = new Date(
        currentYear - 50,
        new Date().getMonth(),
        new Date().getDate(),
      );
      this.maxDate = new Date(
        currentYear + 0,
        new Date().getMonth(),
        new Date().getDate(),
      );
    }
  }
  ngOnInit(): void {
    if (this.data.data.course_type == 1) {
      this.RegisterCourseForm = this.formBuilder.group({
        no_of_child: ['', [Validators.required]],
        students: new FormArray([]),
      });
    } else {
      this.RegisterCourseForm = this.formBuilder.group({
        course_fee: [''],
        payment_method: ['', [Validators.required]],
        discount_code: [''],
        no_of_child: ['', [Validators.required]],
        students: new FormArray([]),
        payment_amount: [''],
        payment_frequency: ['1'],
        parent_name: ['', [Validators.required]],
        email: ['', [Validators.required]],
      });
    }
  }
  get RegisterCourseFormControl() {
    return this.RegisterCourseForm.controls;
  }
  get studentsControl() {
    return this.RegisterCourseForm.controls.students as FormArray;
  }

  onSubmit(token: any) {
    this.token = token;

    if (this.dialogRef.getState() == 0) {
      this.submitted = true;
      if (this.RegisterCourseForm.invalid) {
        this.formMessage = 'Please Fill Required Fields';

        return;
      } else {
        this.loadFlag2 = true;
        this.isLoading = true;

        this.RegisterCourseForm.value.entry_by =
          this.loged_user?.gid ?? 'anonymous';
        this.RegisterCourseForm.value.course_id = this.data.data.id;
        if (this.RegisterCourseForm.value.discount_code && this.discount_id) {
          delete this.RegisterCourseForm.value.discount_code;
          this.RegisterCourseForm.value.discount_id = this.discount_id;
        } else {
          delete this.RegisterCourseForm.value.discount_code;
        }
        this.RegisterCourseForm.value.payment_token = this.token;

        this.RegisterCourseForm.value.students.forEach((element: any) => {
          for (let i in element) {
            if (i == 'dob') {
              element[i] = this.datePipe.transform(element[i], 'yyyy-MM-dd');
            }
            if (i == 'contact_number') {
              element[i] = element[i].replace(/\D+/g, '');
            }
          }
        });
        this.service
          .create(this.dialogData.url, this.RegisterCourseForm.value)
          .subscribe({
            next: (res: any) => {
              console.log('res', res);
              if (res['status'] == 'success' && typeof res.data != 'string') {
                this.loadFlag2 = false;
                if (this.RegisterCourseForm.value.payment_method === '4') {
                  if (this.RegisterCourseForm.value.payment_frequency == '1') {
                    this.dialogRef.close(res.data);
                  } else {
                    this.router.navigate(['../payment'], {
                      queryParams: {
                        id: res.payment.id,
                      },
                      relativeTo: this.route,
                      skipLocationChange: true,
                    });
                    this.dialogRef.close('recurring');
                  }
                } else {
                  this.dialogRef.close(false);
                }
                this.service.showSnackBar(res.message, 'ok', 3000);
              } else if (res && res['status'] && res['status'] == 'Denied') {
                this.service.showSnackBar(
                  'Transaction Denied Please Try Again',
                  'ok',
                  3000,
                );
                this.dialogRef.close(true);

                this.router.navigate(['../payment'], {
                  queryParams: {
                    id: res.payment.id,
                  },
                  relativeTo: this.route,
                  skipLocationChange: true,
                });

                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
              } else if (res && res['status'] && res['status'] == 'error') {
                this.service.showSnackBar(res.message, 'ok', 4000);
                this.dialogRef.close(true);

                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
                this.router.navigate(['/schools/mycourses']);
              } else {
                this.loadFlag2 = false;

                setTimeout(() => {
                  // After the operation is complete, hide the spinner
                  this.isLoading = false;
                }, 2000);
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = [
                  'course_fee',
                  'payment_method',
                  'discount_code',
                  'no_of_child',
                  'payment_amount',
                ];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            },
            error: (error) => {
              this.loadFlag2 = false;
              setTimeout(() => {
                // After the operation is complete, hide the spinner
                this.isLoading = false;
              }, 2000);
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      }
    } else {
      this.dialogRef.close();
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  verifyDiscount() {
    const payload = {
      course_id: this.data.data.id,
      payment_amount: this.course_fee,
      discount_code: this.RegisterCourseForm.value.discount_code,
    };
    this.keys1 = [];
    var s = document.getElementById('discount_code');
    if (s) {
      s.style.borderColor = 'unset';
    }
    this.service
      .create(AppConstants.BASE_URL + AppConstants.VERIFY_DISCOUNT, payload)
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.showresponse = true;
            this.discount_id = res.data.discount_id;
            this.courseFeewithDiscount = res.data.payment_amount;
            this.totalPerMonth =
              this.courseFeewithDiscount / this.numberOfMonths ?? 1;
            this.service.showSnackBar(res.message, 'ok', 3000);
          } else {
            this.topErrorMessage = res.message;
            this.keys = Object.keys(this.topErrorMessage);
            this.keys1 = Object.values(this.topErrorMessage);
            let i;
            let j;
            const name = ['discount_code', 'payment_method'];
            for (i = 0; i < this.keys.length; i++) {
              for (j = 0; j < name.length; j++) {
                if (this.keys[i].match(name[j])) {
                  const documents = document.getElementById(name[j]);
                  if (documents) {
                    documents.style.borderColor = '#a94442';
                    documents.style.display = 'block';
                  }
                }
              }
            }
          }
        },
        error: (error) => {
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
  }
  onChangeStudents(e: any) {
    const childs = !this.isNullOrUndefined(e.target) ? e.target.value : e || 0;
    if (this.studentsControl.length < childs) {
      for (let i = this.studentsControl.length; i < childs; i++) {
        this.studentsControl.push(
          this.formBuilder.group({
            name: ['', [Validators.required]],
            dob: ['', [Validators.required]],
            gender: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            contact_number: [
              '',
              [
                Validators.required,
                Validators.pattern(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/),
              ],
            ],
            pickup_person_name: [''],
            additional_info: [''],
          }),
        );
      }
      let feeTotal = this.dialogData.data.couseFeeTotal;

      if (typeof feeTotal !== 'string') {
        feeTotal = JSON.stringify(feeTotal);
      }

      this.course_fee =
        Number(feeTotal.replace(/[^0-9\.-]+/g, '')) * Number(childs);
      this.courseFeewithDiscount = this.course_fee;

      this.totalPerMonth = this.course_fee / this.numberOfMonths ?? 1;
    } else {
      for (let i = this.studentsControl.length; i >= childs; i--) {
        this.studentsControl.removeAt(i);
      }
      this.course_fee =
        Number(
          JSON.parse(JSON.stringify(this.dialogData.data.course_fee)).replace(
            /[^0-9\.-]+/g,
            '',
          ),
        ) * Number(childs);
      this.totalPerMonth =
        (this.course_fee / this.numberOfMonths ?? 1) * Number(childs) ?? 1;
      this.courseFeewithDiscount = this.course_fee;
    }
  }
  changeName(data: string) {
    if (data === 'Online') {
      this.buttonText = 'Proceed To Checkout';
      this.RegisterCourseForm.controls['payment_frequency'].setValidators([
        Validators.required,
      ]);
      this.RegisterCourseForm.controls[
        'payment_frequency'
      ].updateValueAndValidity();
    } else {
      this.RegisterCourseForm.controls['payment_frequency'].clearValidators();
      this.RegisterCourseForm.controls[
        'payment_frequency'
      ].updateValueAndValidity();
      this.buttonText = 'Proceed';
    }
  }
}
