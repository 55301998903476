<div class="jumbotron">
    <div class="container w-full h-full">
        <div class="row justify-content-center">
            <div class="col-md-12 login-form">
                <div class="col-12 text-center mb-2">
                    <img src="../../../assets/login-logo.png" width="140px" class="text-center" />
                    <h1 class="text-center mt-2">Reset Password</h1>
                    <span class="text-muted">Reset your account password</span>
                </div>
                <ng-container *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                      <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                        <p class="col-md-12 error text-center" id="err">{{value}}</p>
                      </div>
                    </div>
                  </ng-container>
                  <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error">
                      {{errorMsg}}
                    </div>
                  </div>
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">                    
                    <div class="row">
                        <div class="form-group col-12 p-0">
                            <label class="form-label text-muted">Password<span class="asterix"> * </span></label>
                            <input type="password" id="password" formControlName="password" class="form-control" minlength="6"
                                [ngClass]="{ 'is-invalid': submitted && resetPasswordFormControl.password.errors }" />
                            <div *ngIf="submitted && resetPasswordFormControl.password.errors" class="invalid-feedback">
                                <div *ngIf="resetPasswordFormControl.password.errors.required">Password is required.</div>
                                <div *ngIf="resetPasswordFormControl.password.errors.minlength">The password must be atleast 6 chars.</div>
                            </div>
                        </div>
                        <div class="form-group col-12 p-0">
                            <label class="form-label text-muted">Confirm password<span class="asterix"> * </span></label>
                            <input type="password" id="password_confirmation" formControlName="password_confirmation" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && resetPasswordFormControl.password_confirmation.errors }" />
                            <div *ngIf="submitted && resetPasswordFormControl.password_confirmation.errors"
                                class="invalid-feedback">
                                <div *ngIf="resetPasswordFormControl.password_confirmation.errors.required">Confirm password is
                                    required.
                                </div>
                                <div *ngIf="resetPasswordFormControl.password_confirmation.errors.mustMatch">Passwords must match.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12 p-0">
                        <button class="col-12 btn btn-primary">Reset Password <i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
