import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fund-navbar',
  templateUrl: './fund-navbar.component.html',
  styleUrls: ['./fund-navbar.component.css'],
})
export class FundNavbarComponent implements OnInit {
  navActive = true;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  userName: any = null;

  toggleNav() {
    this.navActive = !this.navActive;
  }
  constructor(private router: Router) {
    this.userName = this.loged_user?.name;
  }
  navigateTo(location: string) {
    this.router.navigate([location]);
  }

  ngOnInit(): void {
    console.log('ngOnInit');
  }
}
