<div class="contianer">
    <div class="card">
        <mat-toolbar class="main-header">
            <mat-toolbar-row>
                <span>{{ headings }}</span>
                <span class="example-spacer"></span>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body">
            <form class="form-horizontal nsicc-form validated" [formGroup]="funeralSettingsForm">
                <div class=" col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 " *ngIf=" !formLoader; else
                    showLoader">

                    <div class="row">
                        <mat-form-field class="centered-input">
                            <input matInput type="number" name="funeral_amount" id="funeral_amount" max="1000000"
                                [(ngModel)]="amount" formControlName="funeral_amount" (keyup)="minMaxCheck(amount)"
                                (keypress)="_keyPress($event)" autocomplete="off" step="1"
                                placeholder="$ Funeral Amount"
                                [ngClass]="{ 'is-invalid': submitted && funeralSettingsFormControl.funeral_amount.errors }">
                            <mat-error
                                *ngIf="submitted && funeralSettingsFormControl.funeral_amount.errors  && minError">
                                Minimum amount is 0.01.
                            </mat-error>
                            <mat-error *ngIf="!funeralSettingsFormControl.funeral_amount
                            .errors?.required && maxError">
                                Maximum amount is 9999999.99.
                            </mat-error>
                        </mat-form-field>
                        <div *ngIf="submitted && funeralSettingsFormControl.funeral_amount.errors"
                            class="invalid-feedback">
                            <div *ngIf="funeralSettingsFormControl.funeral_amount?.errors?.required">Funeral amount
                                is
                                required.
                            </div>
                            <div *ngIf="!funeralSettingsFormControl.funeral_amount.errors?.required && minError">
                                Amount should not be less than
                                $0.01.</div>
                            <div *ngIf="!funeralSettingsFormControl.funeral_amount
                              .errors?.required && maxError">Amount should not be greater than
                                $10000000.</div>
                        </div>
                    </div>
                    <div class="row" style="align-items:baseline;">
                        <div *ngIf="submitted && funeralSettingsFormControl.invalid" class="invalid-feedback">
                            Please Fill Required Fields
                        </div>

                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex">
                            <div class="btn-group mr-2">
                                <button (click)="onSubmit()" class="btn btn-primary" name="save">{{buttonText}}
                                    <i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse  mt-1 ml-1"></i></button>
                            </div>

                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #showLoader>
                    <div class="text-center loader">
                        <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                        </mat-progress-spinner>
                    </div>
                </ng-template>
            </form>

        </div>
    </div>
</div>