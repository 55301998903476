<mat-card appearance="outlined" class="view-page mat-card mat-focus-indicator p-0 mx-3">
    <div class="form-field-full">
        <div class="card">
            <div class="card-body">
                <div class="col-12 p-0 d-flex align-items-baseline justify-content-between">
                    <h1 class="title">View Donation</h1>
                    <button class="btn btn-primary" [routerLink]="['/donations/alldonation']" name="back"><i
                            class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                </div>
                <div class="container">
                    <div class="table-container">
                        <div class="col-12 view-content table-responsive">
                            <table *ngIf="!formLoader; else showLoader">
                                <tbody>
                                    <tr>
                                        <th>Donor name</th>
                                        <td>{{data.fname | titlecase}}</td>
                                    </tr>
                                    <tr>
                                        <th>Last name</th>
                                        <td>{{data.lname | titlecase}} </td>
                                    </tr>

                                    <tr>
                                        <th>Email</th>
                                        <td>{{data.email}}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact number</th>
                                        <td>{{data.contact_number}}</td>
                                    </tr>
                                    <tr>
                                        <th>Purpose of donation</th>
                                        <td>{{data.donation_type | titlecase}} </td>
                                    </tr>
                                    <tr>
                                        <th>Donation amount</th>
                                        <td>{{data.donation_amount + ' CAD'}}</td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td>{{data.country}}</td>
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                        <td>{{data.province}}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{{data.city}}</td>
                                    </tr>
                                    <tr>
                                        <th>Payment type</th>
                                        <td>{{data.payment_method == '1'? 'Cash' : data.payment_method == '2'? 'Cheque'
                                            : data.payment_method == '3'? 'E-Transfer' : data.payment_method == '4' ?
                                            'Online' : 'POS' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ng-template #showLoader>
                        <div class="text-center loader">
                            <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                color="accent">
                            </mat-progress-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</mat-card>