<div class="container">
    <div class="current-fundraisers row" *ngIf="fundraiser">

        <div class="fundraiser-card">
            <button class="btn btn-primary" style="max-width:90px;" [routerLink]="['/fundraisers']" name="back"><i
                    class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
            <img class="fundraiser-image" [src]="fundraiser?.cover_image" alt="{{ fundraiser?.title }}" />
            <div>
                <h2>{{ fundraiser?.title }}</h2>
                <div class="fundraiser-description">
                    <p>{{ fundraiser?.description }}</p>
                </div>

                <div class="progress-bar">
                    <div class="fill" [style.width]="calculateProgressBarWidth(fundraiser)"></div>
                </div>
                <div class="fundraiser-amounts">
                    <span style="font-weight:500"> ${{ fundraiser.current_amount }} raised of</span>
                    ${{fundraiser?.target_amount }}
                </div>
            </div>

            <div class="mt-4">

                <div class="row right">

                    <div class=" search-container">
                        <input class="search-input" type="text" [(ngModel)]="searchQuery" placeholder="Search..." />
                        <button class="search-button" (click)="onSearch()">Search</button>
                    </div>
                    <div class="col-2"> <button class="btn btn-primary" (click)="exportExcel()"> Export Funds</button>
                    </div>
                </div>
                <ng-container *ngIf="loading">
                    <div class="text-center loader">
                        <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                        </mat-progress-spinner>
                    </div>
                </ng-container>
                <div class="row mt-2" *ngIf="!loading">
                    <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3 mt-4" *ngIf="funds.length==0">
                        <div class="message-container">
                            <div class="message">
                                <h2>No Contributions Received Yet</h2>
                                <p>It appears that there are currently no funds received for this fundraiser.</p>
                                <p>Keep an eye out for contributions and updates. Your campaign is making a difference!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-6 col-sm-12 col-xs-12 col-xl-4 col-lg-4" *ngFor="let fund of funds">
                        <div class="card  mb-3">
                            <div class="card-header">{{ fund.name }} </div>

                            <div class="card-body">
                                <p class="card-text">
                                    + Has raised the fund by
                                    <span class="fund-amount">${{ fund.fund_amount }}</span>.
                                </p>

                                <p>
                                    <span class="additional-info">Contact Number:</span> {{ fund.contact_number }}
                                </p>
                                <p>
                                    <span class="additional-info">Email:</span> {{ fund.email }}
                                </p>
                                <div *ngIf="fund?.payment?.receipt_file != null"> <span style="cursor:pointer"
                                        (click)="downloadReceipt(fund.payment.id)"
                                        class="badge text-bg-secondary">Receipt</span>
                                </div>

                            </div>
                            <div class="card-footer bg-transparent"><span class="additional-info">Payment
                                    Date:</span>
                                {{
                                fund.payment_date }}</div>

                        </div>
                    </div>

                </div>
                <app-pagination *ngIf="funds.length!=0" class="mt-2" [currentPage]="currentPage" [pageSize]="pageSize"
                    [totalItems]="totalItems" [pageSizes]="[10, 25, 50, 100]" (pageChange)="onPageChange($event)"
                    (pageSizeChange)="onPageSizeChange($event)">
                </app-pagination>
            </div>

        </div>
    </div>