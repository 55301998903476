import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApprovalDialogComponent } from 'src/app/dialogs/approval-dialog/approval-dialog.component';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { DonationsComponent } from 'src/app/donations/donations/donations.component';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseListComponent } from 'src/app/shared/abstract/base-list.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { HeaderConstants } from 'src/app/shared/constants/header-constants';
import { PermissionConstants } from 'src/app/shared/constants/permission-constants';

@Component({
  selector: 'app-all-donations',
  templateUrl: './all-donations.component.html',
  styleUrls: ['./all-donations.component.css'],
})
export class AllDonationsComponent extends BaseListComponent implements OnInit {
  public deleteDonation_url: string;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public donationTypeLookup: { [key: number]: string } = {};

  constructor(
    datamanagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private donation: DonationsComponent,
  ) {
    super();
    this.page = 1;
    this.rows = 10;
    this.service = datamanagerService;
    this.getTableSettings();
    this.searchHits = new Subject<void>();
    this.getListUrl = AppConstants.BASE_URL + AppConstants.DONATIONS_LIST_URL;
    this.deleteDonation_url =
      AppConstants.BASE_URL + AppConstants.DELETEDONATIONS_URL;
    this.columnDefinition = HeaderConstants.AllDonationListHeader;

    this.donation.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_ADD,
    );
    this.donation.delete_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_REMOVE,
    );
    this.donation.view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_VIEW,
    );
    this.donation.edit_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_EDIT,
    );
    this.donation.excel_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_EXCEL,
    );
    this.permissionsCheck = {
      edit_permission: this.donation.edit_permission,
      delete_permission: this.donation.delete_permission,
      view_permission: this.donation.view_permission,
      create_permission: this.donation.create_permission,
      excel_permission: this.donation.excel_permission,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.getDonationType();
  }

  onNotifySelected(selectedRows: object[]) {
    console.log('selectedRows', selectedRows);
  }
  protected getListRes(res: any): void {
    this.loader = true;
    this.rowData = [];
    if (res['status'] == 'success') {
      this.resultsLength = res.data.pagination.total;
      res.data.rowData.forEach((element: any, index: number) => {
        element.donor_fullname = element.fname + ' ' + element.lname;

        for (let key in element) {
          if (key == 'payment_method') {
            element[key] =
              element[key] == '1' || element[key] == 'Cash'
                ? 'Cash'
                : element[key] == '2' || element[key] == 'Cheque'
                  ? 'Cheque'
                  : element[key] == '3' || element[key] == 'E-Transfer'
                    ? 'E-Transfer'
                    : element[key] == '4' || element[key] == 'Online'
                      ? 'Online'
                      : 'POS';
          }
          if (key == 'donation_amount') {
            element[key] = element[key].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          }
          if (key == 'donation_type') {
            if (element[key] in this.donationTypeLookup) {
              element[key] = this.donationTypeLookup[element[key]];
            } else {
              element[key] = '-';
            }
          }

          if (key == 'recurring_count') {
            if (element.recurring_count === null) {
              element.recurring_count = 'One Time';
            } else {
              element.recurring_count = this.generateRecurringSchedule(
                element.recurring_type,
                element.recurring_count,
              );
            }
          }
          if (key == 'end_date') {
            if (element.end_date == null) {
              element.end_date = '--';
            }
          }
          if (key == 'payment_sts') {
            if (!this.isNullOrUndefined(element.recurring_status)) {
              element.donation_Payment_sts =
                element.recurring_status == 1 ? 'Active' : 'Closed';
            } else {
              element.donation_Payment_sts =
                element[key] == null || element[key] == 'Pending'
                  ? 'Pending'
                  : element[key] == 1 || element[key] == 'Completed'
                    ? 'Completed'
                    : 'Verify Payment';
            }
          }
          if (key == 'payment_date' || key == 'receipt_no') {
            element[key] =
              element[key] == null || element[key] == '' ? '--' : element[key];
          }
        }
        this.rowData.push(element);
      });
      this.loader = false;
    } else {
      this.loader = false;
    }
  }
  getRecurringType(recurring_count: any) {
    switch (recurring_count) {
      case 2:
        return 'Days';
      case 3:
        return 'Weeks';
      case 4:
        return 'Months';
      case 5:
        return 'Years';
      default:
        return '';
    }
  }

  protected getListErr(err: any): void {
    console.log('err', err);
    this.loader = false;
  }

  getActions(data: any) {
    if (data.actions === 'delete') {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this Donation?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.id],
          };
          this.service.delete(this.deleteDonation_url, payload).subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([location.pathname]);
                  });
              } else {
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
        }
      });
    } else if (data.actions === 'payment') {
      const paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDDONATIONS_URL +
        '/donationPaymentCheckout/' +
        data.data.id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Do you want to pay this Donation?',
          url: paymentUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: any) => {
        if (confirmed) {
          this.router.navigate(['../checkout'], {
            queryParams: { id: confirmed.payable_id },
            relativeTo: this.route,
          });
        } else if (confirmed == false) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname]);
            });
        } else {
        }
      });
    } else if (data.actions === 'Verify Payment') {
      const verifyPaymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDDONATIONS_URL +
        '/donationPayment/' +
        data.data.id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Payment is done through E-transfer!',
          url: verifyPaymentUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname]);
            });
        }
      });
    } else if (data.actions === 'Unsubscribe') {
      const cancelUrl =
        AppConstants.BASE_URL + `/payments/modify/${data.data.account_id}`;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Are you sure to cancel this Donation ?',
          url: cancelUrl,
          data: data,
          buttonText: {
            ok: 'Yes',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: any) => {
        this.router
          .navigateByUrl('home', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([location.pathname]);
          });
      });
    } else if (data.actions === 'downloadPDF') {
      const downloadPDFUrl =
        AppConstants.BASE_URL +
        '/payment/downloadpdf/' +
        data.data.transcation_id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Do you want to download this Donation Receipt?',
          url: downloadPDFUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname]);
            });
        }
      });
    } else if (data.actions === 'edit') {
      this.router.navigate(['../edit-donation'], {
        queryParams: { id: data.data.id },
        relativeTo: this.route,
      });
    } else if (data.actions === 'receipts') {
      this.router.navigate(
        ['../../payments/receipts/' + data.data.transcation_id],
        {
          relativeTo: this.route,
        },
      );
    } else {
      this.router.navigate(['../view-donation'], {
        queryParams: { id: data.data.id },
        relativeTo: this.route,
      });
    }
  }

  getDonationType() {
    const getListUrl = AppConstants.BASE_URL + '/donation_type';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.dontationTypeList = res.data;
          for (let donationType of this.dontationTypeList) {
            this.donationTypeLookup[donationType.donation_type_id] =
              donationType.type_name;
          }
          if (this.dontationTypeList) {
            this.loader = true;
            this.setUrl();
          }
        } else {
          this.loader = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  exportexcel() {
    this.loadFlag2 = true;
    const exportUrl =
      AppConstants.BASE_URL + AppConstants.ADDDONATIONS_URL + '/export';
    this.service.exportToExcel(exportUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 200) {
          this.loadFlag2 = false;
          this.downloadExcel(res);
          this.service.showSnackBar('File is loading', 'ok', 3000);
        } else {
          this.loadFlag2 = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loadFlag2 = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  generateRecurringSchedule(type: any, count: any) {
    let schedule = count + ' ' + this.getRecurringType(type);
    return schedule;
  }
}
