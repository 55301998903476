import { Component, OnInit } from '@angular/core';
import { VERSION } from '../../../environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  current_year: number = new Date().getFullYear();
  public version_info = VERSION;

  constructor() {}

  ngOnInit(): void {
    console.log('ngOnInit');
  }
}
