import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-fundraiser-details',
  templateUrl: './fundraiser-details.component.html',
  styleUrls: ['./fundraiser-details.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FundraiserDetailsComponent
  extends BaseComponent
  implements OnInit
{
  amount: any;
  donationForm: any;
  firstFetch: boolean = true;
  formMessage: string;
  isLoading: boolean;
  donationUrl: string;
  showtoAdmin: boolean = false;
  urlParam: any;
  userLevelList: any;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
  ) {
    super();
    this.service = dataManagerService;
    this.getUserLevel();

    this.route.params.subscribe((params) => {
      if (params) {
        this.urlParam = params;
      }
    });
  }
  ngOnInit(): void {
    this.getFundraisers();
    this.fetchFundraisers();
  }
  fundraiser: any;
  isReadMore = false;
  toggleReadMore(): void {
    this.isReadMore = !this.isReadMore;
  }
  fetchFundraisers(): void {
    interval(10000).subscribe(() => {
      this.getFundraisers();
    });
  }
  getFundraisers() {
    if (this.urlParam) {
      this.httpClient
        .get(AppConstants.BASE_URL + '/fundraisers/' + this.urlParam['fund_id'])
        .subscribe(
          (data: any) => {
            this.fundraiser = data.data;
            this.maxAmount =
              this.fundraiser?.target_amount - this.fundraiser?.current_amount;
            if (this.firstFetch) {
              this.setFormData();
              this.firstFetch = false;
            }
          },
          (error) => {
            console.error('Error fetching fundraisers:', error);
          },
        );
    }
  }
  calculateProgressBarWidth(fundraiser: any): string {
    const percentage =
      (fundraiser.current_amount / fundraiser.target_amount) * 100;
    if (percentage == 0) return '1' + '%';
    return percentage + '%';
  }
  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  changeCash(cash: any) {
    this.amount = cash;
  }
  setFormData() {
    this.donationForm = this.formBuilder.group({
      fund_amount: [
        ,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.min(0.01),
          Validators.max(this.maxAmount),
        ],
      ],
      first_name: [, [Validators.required]],
      last_name: [, [Validators.required]],
      fund_id: [this.fundraiser.id],
      email: [, [Validators.required, Validators.email]],
      contact_number: [, [Validators.required]],
      has_tax: [, [Validators.required]],
      payment_method: [4, [Validators.required]],
    });
  }
  get donationFormControl() {
    return this.donationForm?.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.submitted = true;
    if (this.donationForm.invalid) {
      this.formMessage = 'Please Fill Required Fields';
      return;
    } else {
      this.isLoading = true;
      this.loadFlag2 = true;
      this.donationForm.value.contact_number =
        this.donationForm.value.contact_number.replace(/\D+/g, '');

      // this.donationForm.value.payment_token = this.token;
      this.donationUrl = AppConstants.BASE_URL + '/fundraisers/store';

      this.service.create(this.donationUrl, this.donationForm.value).subscribe({
        next: (res: any) => {
          if (res && res['status'] && res['status'] == 'success' && res.data) {
            this.loadFlag2 = false;
            this.router.navigate(['../../checkout'], {
              queryParams: {
                id: res.data.payable_id,
                type: 'fund',
              },
              relativeTo: this.route,
            });
          } else if (
            res &&
            res['status'] &&
            res['status'] == 'success' &&
            !res.data &&
            !res['approve']
          ) {
            this.loadFlag2 = false;
            this.router.navigate(['/funded', res.payable_id], {
              skipLocationChange: true,
            });
            this.service.showSnackBar(res.message, 'ok', 3000);
          } else if (
            res &&
            res['status'] &&
            res['status'] == 'success' &&
            res['approve'] == 1
          ) {
            this.service.showSnackBar(res.response.messageText, 'ok', 3000);
            this.router.navigate(['../../payment'], {
              queryParams: {
                id: res.payment.id,
              },
              relativeTo: this.route,
              skipLocationChange: true,
            });
          } else if (res && res['status'] && res['status'] == 'Denied') {
            this.service.showSnackBar(
              'Transaction Denied Please Try Again',
              'ok',
              3000,
            );
            this.service.showSnackBar(res.response.messageText, 'ok', 3000);

            this.router.navigate(['../../payment'], {
              queryParams: {
                id: res.payment.id,
              },
              relativeTo: this.route,
              skipLocationChange: true,
            });
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
          } else if (res && res['status'] && res['status'] == 'error') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
            this.router.navigate(['/login']);
          } else {
            this.loadFlag2 = false;

            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
            this.topErrorMessage = res.message;
            this.keys = Object.keys(this.topErrorMessage);
            this.keys1 = Object.values(this.topErrorMessage);
            let i;
            let j;
            const name = [
              'fund_amount',
              'first_name',
              'last_name',
              'email',
              'contact_number',
              'payment_method',
            ];
            for (i = 0; i < this.keys.length; i++) {
              for (j = 0; j < name.length; j++) {
                if (this.keys[i].match(name[j])) {
                  const documents = document.getElementById(name[j]);
                  if (documents) {
                    documents.style.borderColor = '#a94442';
                    documents.style.display = 'block';
                  }
                }
              }
            }
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.service.showSnackBar(error.message, 'ok', 3000);
          setTimeout(() => {
            // After the operation is complete, hide the spinner
            this.isLoading = false;
          }, 2000);
        },
      });
    }
  }
  getUserLevel() {
    const levelUrl = AppConstants.BASE_URL + '/groups/' + this.loged_user?.gid;
    if (this.loged_user) {
      this.service.getById(levelUrl).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.userLevelList = res.data.row;

            if (
              this.userLevelList.level == 1 ||
              this.userLevelList.level == 2 ||
              this.userLevelList.level == 4
            ) {
              this.showtoAdmin = true;
            } else {
              this.showtoAdmin = false;
            }
            console.log(this.userLevelList, this.paymentMethodList);
            this.formLoader = false;
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.formLoader = false;
          }
        },
        error: (error) => {
          this.formLoader = false;
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    }
  }
}
