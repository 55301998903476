import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-shower-dialog',
  templateUrl: './image-shower-dialog.component.html',
  styleUrls: ['./image-shower-dialog.component.css'],
})
export class ImageShowerDialogComponent implements OnInit {
  cancelButtonText = 'Cancel';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImageShowerDialogComponent>,
  ) {
    if (this.data) {
      console.log('this.data', this.data);
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  ngOnInit(): void {
    console.log('ngOnInit');
  }
}
