import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  public authToken!: string;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (localStorage.getItem('authToken')) {
      this.authToken = JSON.parse(localStorage.getItem('authToken') || 'null');
    }
    if (req.body && Object.keys(req.body).length == 0) {
      const reqUrl = req.clone({
        headers: new HttpHeaders({
          Authorization: this.authToken ? 'Bearer ' + this.authToken : '',
        }),
      });
      return next.handle(reqUrl);
    } else {
      const authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: this.authToken ? 'Bearer ' + this.authToken : '',
          'Content-Type': 'application/json',
        }),
      });
      return next.handle(authReq);
    }
  }
}
