<div class="">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">{{headings}}</h2>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="nsicc-modal-content">
        <div class="form-ajax-box">
            <form class="form-horizontal nsicc-form validated" [formGroup]="schoolClassForm" (ngSubmit)="onSubmit()">
                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                    <div class="row">
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <div class="col-12">
                                <label for="type_name"><strong>Class Name</strong> <span class="asterix">
                                        * </span></label>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <input matInput type="text" name="class_name" id="class_name"
                                        formControlName="class_name" maxlength="30"
                                        [ngClass]="{ 'is-invalid': submitted && schoolClassFormControl.class_name.errors }">
                                </mat-form-field>
                                <div *ngIf="submitted && schoolClassFormControl.class_name.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="schoolClassFormControl.class_name.errors.required">Class name
                                        is
                                        required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <div class="col-12">
                                <label for="type_name"><strong>School Type</strong> <span class="asterix">
                                        * </span></label>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="centered-input form-full-width">
                                    <mat-select aria-label="Default select example" formControlName="school_type"
                                        id="type"
                                        [ngClass]="{ 'is-invalid': submitted && schoolClassFormControl.school_type.errors }">
                                        <mat-option value="" disabled>Select</mat-option>
                                        <mat-option value="2">
                                            Al-Furqan Program </mat-option>
                                        <mat-option value="1">
                                            Islamic Weekend School
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="submitted && schoolClassFormControl.school_type.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="schoolClassFormControl.school_type.errors?.required">School Type is
                                        required.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row" style="align-items:baseline;">
                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex mt-3">
                            <div class="btn-group mr-2">
                                <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i
                                        *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>