<div id="discountView" class="container">
    <div class="card">
        <mat-toolbar class="main-header mb-3">
            <mat-toolbar-row>
                <span>{{ headings }}</span>
                <span class="example-spacer"></span>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3">
            <div class="form-ajax-box">
                <div *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                        <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                            <p class="col-md-12 error text-center" id="err">{{value}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 error">
                            {{errorMsg}}
                        </div>
                    </div>
                </div>
                <form class="form-horizontal sximo-form validated" [formGroup]="discountForm" (ngSubmit)="onSubmit()">
                    <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                        <div class="row mb-3">
                            <label class="form-label col-12">Discount Information <span class="asterix">*</span></label>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="name" class="form-label">Discount Name</label>
                                <input type="text" formControlName="name" id="name" class="form-control"
                                    placeholder="Discount Name" maxlength="30">
                                <div *ngIf="submitted && discountFormControl.name.errors" class="invalid-feedback">
                                    <div *ngIf="discountFormControl.name.errors?.required">Name is required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-6">
                                <label for="type" class="form-label">Type <span class="asterix">*</span></label>
                                <select formControlName="type" id="type" class="form-select"
                                    (change)="changeDiscountType($event.target.value)">
                                    <option value="" disabled>Select</option>
                                    <option *ngFor="let type of discountType" [value]="type.id">{{type.name}}</option>
                                </select>
                                <div *ngIf="submitted && discountFormControl.type.errors" class="invalid-feedback">
                                    <div *ngIf="discountFormControl.type.errors?.required">Type is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="value" class="form-label">Value <span class="asterix">*</span></label>
                                <input type="number" formControlName="value" id="discountvalue" class="form-control"
                                    placeholder="Value" step="0.01" min="0.01" max="10000000">
                                <div *ngIf="submitted && discountFormControl.value.errors" class="invalid-feedback">
                                    <div *ngIf="discountFormControl.value.errors?.required">Value is required.</div>
                                    <div
                                        *ngIf="!discountFormControl.value.errors?.required && (!minError && !maxError) && max == '100'">
                                        Value should be 1 to 100.</div>
                                    <div *ngIf="!discountFormControl.value.errors?.required && minError">Value should
                                        not be less than $0.01.</div>
                                    <div
                                        *ngIf="!discountFormControl.value.errors?.required && maxError && max != '100'">
                                        Value should not be greater than $10,000,000.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-6">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="hasAmountLimit" id="hasAmountLimit"
                                        class="form-check-input"
                                        (change)="onltdAmountCheckboxChange($event.target.checked)">
                                    <label for="hasAmountLimit" class="form-check-label">Limited Amount:</label>
                                </div>
                                <div class="row m-0" *ngIf="showAmounts">
                                    <div class="col-lg-6">
                                        <label for="minAmount" class="form-label">Min:<span
                                                class="asterix">*</span></label>
                                        <input type="number" formControlName="minAmount" id="minAmount"
                                            class="form-control" placeholder="Min" min="0">
                                        <div *ngIf="submitted && discountFormControl.minAmount.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.minAmount.errors?.required">Min is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="maxAmount" class="form-label">Max:<span
                                                class="asterix">*</span></label>
                                        <input type="number" formControlName="maxAmount" id="maxAmount"
                                            class="form-control" placeholder="Max" min="0">
                                        <div *ngIf="submitted && discountFormControl.maxAmount.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.maxAmount.errors?.required">Max is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="hasQtyLimit" id="hasQtyLimit"
                                        class="form-check-input"
                                        (change)="onltdQuntityCheckboxChange($event.target.checked)">
                                    <label for="hasQtyLimit" class="form-check-label">Limited quantity:</label>
                                </div>
                                <div class="row m-0" *ngIf="showQuentity">
                                    <div class="col-lg-6">
                                        <label for="startQty" class="form-label">Min:<span
                                                class="asterix">*</span></label>
                                        <input type="number" formControlName="startQty" id="startQty"
                                            class="form-control" placeholder="Min" min="0">
                                        <div *ngIf="submitted && discountFormControl.startQty.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.startQty.errors?.required">Min is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="endQty" class="form-label">Max:<span
                                                class="asterix">*</span></label>
                                        <input type="number" formControlName="endQty" id="endQty" class="form-control"
                                            placeholder="Max" min="0">
                                        <div *ngIf="submitted && discountFormControl.endQty.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.endQty.errors?.required">Max is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mb-4">
                                <div *ngIf="headings === 'Update Discount'" class="form-check form-switch">
                                    <input class="form-check-input" formControlName="isActive" id="isActive"
                                        name="isActive" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                                    <label class="form-check-label" for="isActive">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex">
                                <button class="btn btn-primary mr-2" [disabled]="loadFlag2" name="save">{{buttonText}}
                                    <span *ngIf="loadFlag2" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span></button>
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center loader" *ngIf="formLoader">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>