<div class="">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">{{headings}}</h2>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="nsicc-modal-content">
        <div class="form-ajax-box">
            <form class="form-horizontal nsicc-form validated" [formGroup]="expenseAccountForm"
                (ngSubmit)="onSubmit()">
                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                    <div class="row">
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <label for="account_number">Account Number <span class="asterix">
                                    * </span></label>
                            <input type="tel" name="account_number" id="account_number" formControlName="account_number"
                                class="form-control" [maxlength]="17" (keypress)="numericOnly($event)"
                                [ngClass]="{ 'is-invalid': submitted && expenseAccountFormControl.account_number.errors }">
                            <div *ngIf="submitted && expenseAccountFormControl.account_number.errors"
                                class="invalid-feedback">
                                <div *ngIf="expenseAccountFormControl.account_number.errors.required">Account number is
                                    required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="align-items:baseline;">
                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex mt-3">
                            <div class="btn-group mr-2">
                                <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i *ngIf="loadFlag2"
                                        class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
