/**
 * Created by arun on 30/5/18.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DataManagerService } from '../DataManager/data-manager.service';
@Injectable()
export class AuthGuardService  {
  protected _router: Router;
  protected _dataManager: DataManagerService;
  protected _userToken: any;

  constructor(router: Router, dataManager: DataManagerService) {
    this._router = router;
    this._dataManager = dataManager;
    this._userToken = JSON.parse(localStorage.getItem('authToken') || 'null');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._dataManager.LoginStatus()) {
      return true;
    }
    const url = state.url;
    this._router.navigate(['/login'], {
      queryParams: {
        returnUrl: url,
      },
    });
    return false;
  }
}
