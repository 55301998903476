import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataManagerService } from '../../services/DataManager/data-manager.service';
import { BaseComponent } from '../../shared/abstract/base.component';
import { AppConstants } from '../../shared/constants/app-constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  public forgotPasswordForm!: FormGroup;
  public loginUrl = AppConstants.BASE_URL + AppConstants.RESET_PASSWORD_URL;

  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private dataManagerService: DataManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      credit_email: ['', [Validators.required, Validators.email]],
      mobile: true,
    });
  }
  get forgotFormControl() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {
      this.loadFlag2 = true;
      this.dataManagerService
        .resetPassword(this.loginUrl, this.forgotPasswordForm.value)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              this._router.navigate(['/login']);
            } else {
              this.loadFlag2 = false;
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
  gotoForgot() {
    this._router.navigate(['/forgot']);
  }
  gotoDonate() {}
}
