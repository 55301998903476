<div class="pagination">
    <button (click)="changePage(1)" [disabled]="currentPage === 1">First</button>
    <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
    <span>{{ currentPage }} / {{ totalPages }}</span>
    <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">Next</button>
    <button (click)="changePage(totalPages)" [disabled]="currentPage === totalPages">Last</button>
    <span>Show:</span>
    <select [(ngModel)]="pageSize" (change)="changePageSize($event.target.value)">
        <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
    </select>
</div>