<!-- <div class="title mb-4 col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12" *ngIf="!matchUrl('/checkout') ">
    <mat-card-title>{{heading()}}</mat-card-title>
    <button class="btn btn-primary" (click)="createNew()" *ngIf="create && !matchUrl('add-donation') && !matchUrl('edit-donation') && !matchUrl('view-donation') && !matchUrl('checkout')">{{btnNameupdate()}}</button>
</div>
<div class="main-content">
<router-outlet></router-outlet>
</div> -->
<div class="row title justify-content-end">
    <div class="col-12 col-md-6 col-lg-4 col-xl-4 col-sm-6 text-lg-center text-xl-center" *ngIf="!matchUrl('/checkout') ">
        <mat-card-title class="text-lg-center text-xl-center">{{heading()}}</mat-card-title>
    </div>
    <div class="mb-4 col-12 col-md-6 col-lg-4 col-xl-4 col-sm-6 text-end"  *ngIf="!matchUrl('/checkout') ">        
        <button class="btn btn-primary" (click)="createNew()" *ngIf="create && !matchUrl('add-donation') && !matchUrl('edit-donation') && !matchUrl('view-donation') && !matchUrl('checkout')">{{btnNameupdate()}}</button>
    </div>
</div>
<div class="main-content">
    <router-outlet></router-outlet>
</div>