<div class="content">
    <div class="col-lg-4 col-xl-4 col-md-8 col-sm-6 col-12 form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
            [(ngModel)]="searchText" (input)="searchHits.next(true)" placeholder="Search" />
    </div>
    <div>
        <div appearance="outlined" class="list">
            <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true" [loader]="loader"
                [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                (getPagination)="getPageData($event)" (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                [sqColumnDefinition]="columnDefinition" [length]="resultsLength"></app-generic-table>
        </div>
    </div>
</div>